import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { NgxPopUpService } from '@pwc/appkit-styled-lib';
import { ResponseModelOfDeleteUserResult } from '../services/api/models';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {

  constructor(
    private ngxPopUpService: NgxPopUpService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorBE = error.error as any;
        if (errorBE.errors) {
          const arr = Object.entries(errorBE.errors).map(([key, value]) => ({ key, value }));
          arr.forEach(err => {
            (err.value as any[]).forEach(errVal => {
              this.ngxPopUpService.newError(errVal);
            })
          })
        }
        if (errorBE.error_description) {
          this.ngxPopUpService.newError(errorBE.error_description);
        }

        let errorMessage = 'An unknown error occurred';
        /*if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }*/
        console.error(errorMessage);
        return throwError(() => error)
      })
    );
  }
}

interface BeError {
  error: string;
  error_description: string;
}