/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CopyFormVersionApiParameters } from '../../models/copy-form-version-api-parameters';
import { ResponseModelOfCopyFormVersionResult } from '../../models/response-model-of-copy-form-version-result';

export interface CopyFormVersionCopyFormVersion$Params {
  
    /**
     * Copy form version API parameters.
     */
    body: CopyFormVersionApiParameters
}

export function copyFormVersionCopyFormVersion(http: HttpClient, rootUrl: string, params: CopyFormVersionCopyFormVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCopyFormVersionResult>> {
  const rb = new RequestBuilder(rootUrl, copyFormVersionCopyFormVersion.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfCopyFormVersionResult>;
    })
  );
}

copyFormVersionCopyFormVersion.PATH = '/api/Forms/CopyFormVersion';
