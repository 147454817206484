/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApplicationConfigurationService } from './services/application-configuration.service';
import { VersionService } from './services/version.service';
import { HealthCheckService } from './services/health-check.service';
import { RiskResultService } from './services/risk-result.service';
import { RiskTypesService } from './services/risk-types.service';
import { SubmitRiskAssesmentService } from './services/submit-risk-assesment.service';
import { SupportService } from './services/support.service';
import { RoleService } from './services/role.service';
import { UserService } from './services/user.service';
import { AllTasksService } from './services/all-tasks.service';
import { AssignModuleService } from './services/assign-module.service';
import { AssignSectionService } from './services/assign-section.service';
import { ChangeSectionStateService } from './services/change-section-state.service';
import { CopyFormVersionService } from './services/copy-form-version.service';
import { FormAttachmentsService } from './services/form-attachments.service';
import { FormDataService } from './services/form-data.service';
import { FormDataValidationService } from './services/form-data-validation.service';
import { FormDefinitionService } from './services/form-definition.service';
import { FormSetDefinitionService } from './services/form-set-definition.service';
import { FormVersionsService } from './services/form-versions.service';
import { ModuleService } from './services/module.service';
import { ModuleDefinitionService } from './services/module-definition.service';
import { ProjectTasksService } from './services/project-tasks.service';
import { ApproveProjectRequestService } from './services/approve-project-request.service';
import { ChangeProjectManagerService } from './services/change-project-manager.service';
import { ConformityAssesmentService } from './services/conformity-assesment.service';
import { ProjectCompanyAccessService } from './services/project-company-access.service';
import { ProjectService } from './services/project.service';
import { ProjectDetailService } from './services/project-detail.service';
import { ProjectRequestService } from './services/project-request.service';
import { RejectProjectRequestService } from './services/reject-project-request.service';
import { RestoreProjectService } from './services/restore-project.service';
import { UpdateProjectTeamService } from './services/update-project-team.service';
import { TemplateDefinitionService } from './services/template-definition.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApplicationConfigurationService,
    VersionService,
    HealthCheckService,
    RiskResultService,
    RiskTypesService,
    SubmitRiskAssesmentService,
    SupportService,
    RoleService,
    UserService,
    AllTasksService,
    AssignModuleService,
    AssignSectionService,
    ChangeSectionStateService,
    CopyFormVersionService,
    FormAttachmentsService,
    FormDataService,
    FormDataValidationService,
    FormDefinitionService,
    FormSetDefinitionService,
    FormVersionsService,
    ModuleService,
    ModuleDefinitionService,
    ProjectTasksService,
    ApproveProjectRequestService,
    ChangeProjectManagerService,
    ConformityAssesmentService,
    ProjectCompanyAccessService,
    ProjectService,
    ProjectDetailService,
    ProjectRequestService,
    RejectProjectRequestService,
    RestoreProjectService,
    UpdateProjectTeamService,
    TemplateDefinitionService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
