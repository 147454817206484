import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { HttpActivityIndication } from '../models/http-activity-indication.model';
import { HttpActivityIndicationService } from '../services/http-activity-indication.service';

@Injectable()
export class HttpActivityIndicationInterceptor implements HttpInterceptor {
  httpActivityCounter: number = 0;

  constructor(
    private httpActivityIndicationService: HttpActivityIndicationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.httpActivityCounter += 1;
    const thisRequestId = this.httpActivityCounter;
    const newRequest: HttpActivityIndication = {
      id: this.httpActivityCounter,
      startTime: new Date(),
      completed: false
    }
    const currentRequestHistory = this.httpActivityIndicationService.events$.getValue();
    this.httpActivityIndicationService.events$.next([...currentRequestHistory,newRequest]);
    return next.handle(request).pipe(
      finalize(() => {
        const currentRequestHistory = this.httpActivityIndicationService.events$.getValue();
        const index = currentRequestHistory.findIndex(r => r.id === thisRequestId);
        if (index > -1) {
          const r = {...currentRequestHistory[index]};
          r.completed = true;
          r.endTime = new Date();
          currentRequestHistory.splice(index,1);
          const newRequestHistory = [...currentRequestHistory,r];
          newRequestHistory.sort((a,b) => {
            return a.startTime.getTime() - b.startTime.getTime();
          });
          this.httpActivityIndicationService.events$.next(newRequestHistory)
        }
      })
    );
  }
}
