/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { formSetDefinitionImportFormSet } from '../fn/form-set-definition/form-set-definition-import-form-set';
import { FormSetDefinitionImportFormSet$Params } from '../fn/form-set-definition/form-set-definition-import-form-set';

@Injectable({ providedIn: 'root' })
export class FormSetDefinitionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formSetDefinitionImportFormSet()` */
  static readonly FormSetDefinitionImportFormSetPath = '/api/Forms/FormSetDefinition';

  /**
   * Imports form set and returns result of the operation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formSetDefinitionImportFormSet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formSetDefinitionImportFormSet$Response(params: FormSetDefinitionImportFormSet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return formSetDefinitionImportFormSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports form set and returns result of the operation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formSetDefinitionImportFormSet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formSetDefinitionImportFormSet(params: FormSetDefinitionImportFormSet$Params, context?: HttpContext): Observable<void> {
    return this.formSetDefinitionImportFormSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
