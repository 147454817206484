/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { projectCreateProject } from '../fn/project/project-create-project';
import { ProjectCreateProject$Params } from '../fn/project/project-create-project';
import { projectDeleteProject } from '../fn/project/project-delete-project';
import { ProjectDeleteProject$Params } from '../fn/project/project-delete-project';
import { projectDownloadProject } from '../fn/project/project-download-project';
import { ProjectDownloadProject$Params } from '../fn/project/project-download-project';
import { projectGetProjectFilters } from '../fn/project/project-get-project-filters';
import { ProjectGetProjectFilters$Params } from '../fn/project/project-get-project-filters';
import { projectGetProjects } from '../fn/project/project-get-projects';
import { ProjectGetProjects$Params } from '../fn/project/project-get-projects';
import { projectTechnicalDocumentation } from '../fn/project/project-technical-documentation';
import { ProjectTechnicalDocumentation$Params } from '../fn/project/project-technical-documentation';
import { projectUpdateProject } from '../fn/project/project-update-project';
import { ProjectUpdateProject$Params } from '../fn/project/project-update-project';
import { ResponseModelOfCreateProjectResult } from '../models/response-model-of-create-project-result';
import { ResponseModelOfDeleteProjectResult } from '../models/response-model-of-delete-project-result';
import { ResponseModelOfGetProjectListFiltersResult } from '../models/response-model-of-get-project-list-filters-result';
import { ResponseModelOfGetProjectListResult } from '../models/response-model-of-get-project-list-result';
import { ResponseModelOfUpdateProjectResult } from '../models/response-model-of-update-project-result';

@Injectable({ providedIn: 'root' })
export class ProjectService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectGetProjects()` */
  static readonly ProjectGetProjectsPath = '/api/Projects/Project';

  /**
   * Returns project/s according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectGetProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetProjects$Response(params: ProjectGetProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectListResult>> {
    return projectGetProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns project/s according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectGetProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetProjects(params: ProjectGetProjects$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectListResult> {
    return this.projectGetProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectListResult>): ResponseModelOfGetProjectListResult => r.body)
    );
  }

  /** Path part for operation `projectUpdateProject()` */
  static readonly ProjectUpdateProjectPath = '/api/Projects/Project';

  /**
   * Returns id of project updated.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectUpdateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectUpdateProject$Response(params: ProjectUpdateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUpdateProjectResult>> {
    return projectUpdateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns id of project updated.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectUpdateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectUpdateProject(params: ProjectUpdateProject$Params, context?: HttpContext): Observable<ResponseModelOfUpdateProjectResult> {
    return this.projectUpdateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfUpdateProjectResult>): ResponseModelOfUpdateProjectResult => r.body)
    );
  }

  /** Path part for operation `projectCreateProject()` */
  static readonly ProjectCreateProjectPath = '/api/Projects/Project';

  /**
   * Returns id of project created.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectCreateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCreateProject$Response(params: ProjectCreateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCreateProjectResult>> {
    return projectCreateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns id of project created.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectCreateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCreateProject(params: ProjectCreateProject$Params, context?: HttpContext): Observable<ResponseModelOfCreateProjectResult> {
    return this.projectCreateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfCreateProjectResult>): ResponseModelOfCreateProjectResult => r.body)
    );
  }

  /** Path part for operation `projectDeleteProject()` */
  static readonly ProjectDeleteProjectPath = '/api/Projects/Project';

  /**
   * Returns id of project deleted.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectDeleteProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectDeleteProject$Response(params: ProjectDeleteProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfDeleteProjectResult>> {
    return projectDeleteProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns id of project deleted.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectDeleteProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectDeleteProject(params: ProjectDeleteProject$Params, context?: HttpContext): Observable<ResponseModelOfDeleteProjectResult> {
    return this.projectDeleteProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfDeleteProjectResult>): ResponseModelOfDeleteProjectResult => r.body)
    );
  }

  /** Path part for operation `projectGetProjectFilters()` */
  static readonly ProjectGetProjectFiltersPath = '/api/Projects/Project/filters';

  /**
   * Returns project filters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectGetProjectFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetProjectFilters$Response(params: ProjectGetProjectFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectListFiltersResult>> {
    return projectGetProjectFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns project filters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectGetProjectFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetProjectFilters(params: ProjectGetProjectFilters$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectListFiltersResult> {
    return this.projectGetProjectFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectListFiltersResult>): ResponseModelOfGetProjectListFiltersResult => r.body)
    );
  }

  /** Path part for operation `projectDownloadProject()` */
  static readonly ProjectDownloadProjectPath = '/api/Projects/Project/download';

  /**
   * Download zipped project with pdf report and attachments..
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectDownloadProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectDownloadProject$Response(params: ProjectDownloadProject$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return projectDownloadProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Download zipped project with pdf report and attachments..
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectDownloadProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectDownloadProject(params: ProjectDownloadProject$Params, context?: HttpContext): Observable<Blob> {
    return this.projectDownloadProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `projectTechnicalDocumentation()` */
  static readonly ProjectTechnicalDocumentationPath = '/api/Projects/Project/technical-documentation';

  /**
   * Download zipped technical docomentation with pdf report and attachments..
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTechnicalDocumentation()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTechnicalDocumentation$Response(params: ProjectTechnicalDocumentation$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return projectTechnicalDocumentation(this.http, this.rootUrl, params, context);
  }

  /**
   * Download zipped technical docomentation with pdf report and attachments..
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectTechnicalDocumentation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTechnicalDocumentation(params: ProjectTechnicalDocumentation$Params, context?: HttpContext): Observable<Blob> {
    return this.projectTechnicalDocumentation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
