/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ResponseModelOfGetRiskAssesmentResultResult } from '../models/response-model-of-get-risk-assesment-result-result';
import { riskResultGetRiskAssesmentResult } from '../fn/risk-result/risk-result-get-risk-assesment-result';
import { RiskResultGetRiskAssesmentResult$Params } from '../fn/risk-result/risk-result-get-risk-assesment-result';

@Injectable({ providedIn: 'root' })
export class RiskResultService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `riskResultGetRiskAssesmentResult()` */
  static readonly RiskResultGetRiskAssesmentResultPath = '/api/RiskAssesment/RiskResult';

  /**
   * Get risk assasment result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riskResultGetRiskAssesmentResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  riskResultGetRiskAssesmentResult$Response(params: RiskResultGetRiskAssesmentResult$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetRiskAssesmentResultResult>> {
    return riskResultGetRiskAssesmentResult(this.http, this.rootUrl, params, context);
  }

  /**
   * Get risk assasment result.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `riskResultGetRiskAssesmentResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riskResultGetRiskAssesmentResult(params: RiskResultGetRiskAssesmentResult$Params, context?: HttpContext): Observable<ResponseModelOfGetRiskAssesmentResultResult> {
    return this.riskResultGetRiskAssesmentResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetRiskAssesmentResultResult>): ResponseModelOfGetRiskAssesmentResultResult => r.body)
    );
  }

}
