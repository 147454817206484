/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { projectTasksFilters } from '../fn/project-tasks/project-tasks-filters';
import { ProjectTasksFilters$Params } from '../fn/project-tasks/project-tasks-filters';
import { projectTasksGetProjectTask } from '../fn/project-tasks/project-tasks-get-project-task';
import { ProjectTasksGetProjectTask$Params } from '../fn/project-tasks/project-tasks-get-project-task';
import { ResponseModelOfGetProjectTaskFiltersResult } from '../models/response-model-of-get-project-task-filters-result';
import { ResponseModelOfGetProjectTaskListResult } from '../models/response-model-of-get-project-task-list-result';

@Injectable({ providedIn: 'root' })
export class ProjectTasksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectTasksGetProjectTask()` */
  static readonly ProjectTasksGetProjectTaskPath = '/api/Forms/ProjectTasks';

  /**
   * Project's tasks of currently logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTasksGetProjectTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTasksGetProjectTask$Response(params: ProjectTasksGetProjectTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectTaskListResult>> {
    return projectTasksGetProjectTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Project's tasks of currently logged in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectTasksGetProjectTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTasksGetProjectTask(params: ProjectTasksGetProjectTask$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectTaskListResult> {
    return this.projectTasksGetProjectTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectTaskListResult>): ResponseModelOfGetProjectTaskListResult => r.body)
    );
  }

  /** Path part for operation `projectTasksFilters()` */
  static readonly ProjectTasksFiltersPath = '/api/Forms/ProjectTasks/filters';

  /**
   * Filters for filtering project tasks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTasksFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTasksFilters$Response(params: ProjectTasksFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectTaskFiltersResult>> {
    return projectTasksFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * Filters for filtering project tasks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectTasksFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTasksFilters(params: ProjectTasksFilters$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectTaskFiltersResult> {
    return this.projectTasksFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectTaskFiltersResult>): ResponseModelOfGetProjectTaskFiltersResult => r.body)
    );
  }

}
