import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToHumanReadable'
})
export class CamelCaseToHumanReadablePipe implements PipeTransform {

  transform(value: string): string {
    const words = value.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join them
    const result = words.map((word,index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
      return word.charAt(0).toLowerCase() + word.slice(1)
    }).join(' ');

    return result;
  }

}
