/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrderTypeModel } from '../../models/order-type-model';
import { ProjectTaskSortByType } from '../../models/project-task-sort-by-type';
import { ResponseModelOfGetProjectTaskListResult } from '../../models/response-model-of-get-project-task-list-result';
import { SectionStatus } from '../../models/section-status';

export interface ProjectTasksGetProjectTask$Params {

/**
 * Gets or sets a requested projectId.
 */
  ProjectId: string;

/**
 * Gets or sets a requested module.
 */
  module?: Array<string> | null;

/**
 * Gets or sets a requested status.
 */
  status?: Array<SectionStatus> | null;

/**
 * Gets or sets project task sort type.
 */
  projectTaskSortByType?: ProjectTaskSortByType;

/**
 * Gets or sets project task order type.
 */
  orderType?: OrderTypeModel;

/**
 * Gets or sets a requested recordFrom.
 */
  recordFrom?: number | null;

/**
 * Gets or sets requested recordFrom.
 */
  recordTo?: number | null;
}

export function projectTasksGetProjectTask(http: HttpClient, rootUrl: string, params: ProjectTasksGetProjectTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectTaskListResult>> {
  const rb = new RequestBuilder(rootUrl, projectTasksGetProjectTask.PATH, 'get');
  if (params) {
    rb.query('ProjectId', params.ProjectId, {});
    rb.query('module', params.module, {"style":"form","explode":true});
    rb.query('status', params.status, {"style":"form","explode":true});
    rb.query('projectTaskSortByType', params.projectTaskSortByType, {});
    rb.query('orderType', params.orderType, {});
    rb.query('recordFrom', params.recordFrom, {});
    rb.query('recordTo', params.recordTo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetProjectTaskListResult>;
    })
  );
}

projectTasksGetProjectTask.PATH = '/api/Forms/ProjectTasks';
