/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfGetFormDataResult } from '../../models/response-model-of-get-form-data-result';

export interface FormDataGetFormData$Params {

/**
 * ID of the requested form instance. If formInstanceIdis not provided, returns all known form datas.
 */
  formInstanceId: string;

/**
 * ID of requested version of form. If formVersionIdis not provider, returns latest version of form values.
 */
  formVersionId?: string | null;
}

export function formDataGetFormData(http: HttpClient, rootUrl: string, params: FormDataGetFormData$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormDataResult>> {
  const rb = new RequestBuilder(rootUrl, formDataGetFormData.PATH, 'get');
  if (params) {
    rb.query('formInstanceId', params.formInstanceId, {});
    rb.query('formVersionId', params.formVersionId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetFormDataResult>;
    })
  );
}

formDataGetFormData.PATH = '/api/Forms/FormData';
