import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  NgxLightModeService,
  NgxPopUpService,
  TAppkitTheme,
  ThemeService,
  appkitThemes,
} from '@pwc/appkit-styled-lib';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiConfiguration } from './services/api/api-configuration';
import { HealthCheckService, UserService } from './services/api/services';
import { HttpActivityIndicationService } from './services/http-activity-indication.service';
import { SharedUserService } from './services/shared-user.service';
import { getEnvironment, getEnvironmentBadge } from './funcitons/utils';
import { EnvironmentT } from './models/general.model';
import { RouteService } from './services/route.service';
import { Location } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private xx: HealthCheckService,
    private httpActivityIndicationService: HttpActivityIndicationService,
    private ngxLightModeService: NgxLightModeService,
    private oidcSecurityService: OidcSecurityService,
    private sharedUserService: SharedUserService,
    private themeService: ThemeService,
    private ngxPopUpService: NgxPopUpService,
    private viewContainerRef: ViewContainerRef,
    private userService: UserService,
    private router: Router,
    private titleService: Title,
    private apiService: ApiConfiguration,
    private activatedRoute: ActivatedRoute,
    private routeService: RouteService,
    private location: Location,
    private formBuilder: FormBuilder
  ) {
    this.apiService.rootUrl = environment.apiRootUrl;
    this.ngxPopUpService.viewContainerRef = this.viewContainerRef;
    this.ngxPopUpService.positiveSnacksDuration = 12000;
    const simulatedUser = localStorage.getItem('simulatedUser');
    if (simulatedUser) {
      this.simulatedUser = JSON.parse(simulatedUser);
    }

    const env: EnvironmentT = getEnvironment();
    this.badgeTest = getEnvironmentBadge(env);
    if (env !== EnvironmentT.PROD) {
      this.changeTitle(`AICT-${env}`);
    } else {
      this.changeTitle(`AICT`);
    }

  }

  checkBox = this.formBuilder.group({
    checkBoxValue: new FormControl <boolean>(false)
  })

  badgeTest: string | null = null;

  loading$ = this.httpActivityIndicationService.loading$;

  healthCheck$: Observable<void> = this.xx.healthCheckHealtCheck();

  ngOnInit() {
    if(this.location.path() === '/decline-logged-out') {
      this.termsAndConditionsDisplay = false;
    }
    else {
      const termsAndConditionsDisplay = JSON.parse(localStorage.getItem('termsAndConditionsDisplay'));
      if (termsAndConditionsDisplay === null) {
        this.termsAndConditionsDisplay = true;
      } else {
        this.termsAndConditionsDisplay = termsAndConditionsDisplay;
      }  
    }
    const storageTenant = this.sharedUserService.storageTenant();
    const storageUser = this.sharedUserService.storageUser();

    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        const { isAuthenticated, userData, accessToken, idToken, configId } =
          loginResponse;
        if (loginResponse.isAuthenticated) {
          if (storageTenant && !storageUser) {
            this.sharedUserService.changeUser(null, storageTenant);
          }

          if (
            (storageTenant && storageUser) ||
            (!storageTenant && storageUser)
          ) {
            this.sharedUserService.changeUser(storageUser, storageTenant);
          }

          if (!storageTenant && !storageUser) {
            this.sharedUserService.changeUser();
          }
        }
      });
    const theme: TAppkitTheme = localStorage.getItem('theme') as TAppkitTheme;
    if (theme) {
      this.themeService.setTheme(theme);
    } else {
      this.themeService.setTheme('orange');
    }
    this.theme$.subscribe();
  }

  theme$ = this.themeService.theme$.pipe(
    tap((theme: TAppkitTheme) => {
      localStorage.setItem('theme', theme);
    })
  );

  isAuth$: Observable<boolean> = this.oidcSecurityService.isAuthenticated$.pipe(
    map((d) => d.isAuthenticated)
  );

  currentTenantUser$ = this.sharedUserService.currentTenantUser$;
  tenants$ = this.sharedUserService.tenantList$;

  projectUsers$ = this.sharedUserService.currentTenantUser$.pipe(
    switchMap((data) =>
      this.userService.userGetUsers({ tenantId: data.tenant.id })
    ),
    map((data) => {
      console.log(data.result.users);
      return data.result.users;
    })
  );

  simulatedUser; // assign in constructor

  clearSimulatedUser() {
    this.sharedUserService.clearSimulatedUser();
  }

  lightMode$: Observable<'light' | 'dark'> =
    this.ngxLightModeService.lightMode.asObservable();
  activeTheme$: Observable<TAppkitTheme> =
    this.themeService.theme$.asObservable();

  changeMode(lightMode: 'light' | 'dark') {
    const newLightMode: 'light' | 'dark' =
      lightMode === 'dark' ? 'light' : 'dark';
    this.ngxLightModeService.changeLightMode(newLightMode);
    localStorage.setItem('lightMode', lightMode);
  }

  changeTheme(theme: TAppkitTheme) {
    const index = appkitThemes.findIndex((v) => v === theme);
    let newIndex = index + 1;
    if (newIndex > appkitThemes.length - 1) {
      newIndex = 0;
    }
    this.themeService.setTheme(appkitThemes[newIndex]);
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/logged-out']);
  }

  declineLogout() {
    console.log(sessionStorage, 'STORAGE')
    sessionStorage.clear();
    this.router.navigate(['/decline-logged-out'])
  }

  changeUser($event) {
    this.sharedUserService.changeUser(
      $event,
      this.sharedUserService.getCurrentTenantUser().tenant,
      [() => this.routeService.goHome()]
    );
    this.simulatedUser = this.sharedUserService.storageUser();
    ;
  }

  changeTenant(tenant) {
    this.sharedUserService.changeTenant(tenant);
  }

  changeTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  year = new Date().getFullYear();

  goToHome() {
    this.routeService.goHome();
  }

  termsAndConditionsDisplay = true;

  Accept() {
    localStorage.setItem('termsAndConditionsDisplay',JSON.stringify(false));
    this.termsAndConditionsDisplay = false;
  }

  Decline() {
    localStorage.setItem('termsAndConditionsDisplay',JSON.stringify(true));
    this.termsAndConditionsDisplay = false;
    this.declineLogout();
  }
}
