/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfGetUserInfoResult } from '../../models/response-model-of-get-user-info-result';

export interface UserGetUserInfo$Params {

/**
 * If provided, it will retrieve user from the given tenant and will return this tenant and related project roles. If not provided (set to null), it will provide tenant information, but not project related data.
 */
  tenantId?: string | null;
}

export function userGetUserInfo(http: HttpClient, rootUrl: string, params?: UserGetUserInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetUserInfoResult>> {
  const rb = new RequestBuilder(rootUrl, userGetUserInfo.PATH, 'get');
  if (params) {
    rb.query('tenantId', params.tenantId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetUserInfoResult>;
    })
  );
}

userGetUserInfo.PATH = '/api/Users/Users/userinfo';
