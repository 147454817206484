/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ResponseModelOfUpdateTeamResult } from '../models/response-model-of-update-team-result';
import { updateProjectTeamUpdateUser } from '../fn/update-project-team/update-project-team-update-user';
import { UpdateProjectTeamUpdateUser$Params } from '../fn/update-project-team/update-project-team-update-user';

@Injectable({ providedIn: 'root' })
export class UpdateProjectTeamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateProjectTeamUpdateUser()` */
  static readonly UpdateProjectTeamUpdateUserPath = '/api/Projects/Team';

  /**
   * Updates project team in the database.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectTeamUpdateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectTeamUpdateUser$Response(params: UpdateProjectTeamUpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUpdateTeamResult>> {
    return updateProjectTeamUpdateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates project team in the database.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProjectTeamUpdateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectTeamUpdateUser(params: UpdateProjectTeamUpdateUser$Params, context?: HttpContext): Observable<ResponseModelOfUpdateTeamResult> {
    return this.updateProjectTeamUpdateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfUpdateTeamResult>): ResponseModelOfUpdateTeamResult => r.body)
    );
  }

}
