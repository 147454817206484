/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { projectRequestCreateProjectRequest } from '../fn/project-request/project-request-create-project-request';
import { ProjectRequestCreateProjectRequest$Params } from '../fn/project-request/project-request-create-project-request';
import { projectRequestDeleteProjectRequest } from '../fn/project-request/project-request-delete-project-request';
import { ProjectRequestDeleteProjectRequest$Params } from '../fn/project-request/project-request-delete-project-request';
import { projectRequestGetProjectFilters } from '../fn/project-request/project-request-get-project-filters';
import { ProjectRequestGetProjectFilters$Params } from '../fn/project-request/project-request-get-project-filters';
import { projectRequestGetProjectRequests } from '../fn/project-request/project-request-get-project-requests';
import { ProjectRequestGetProjectRequests$Params } from '../fn/project-request/project-request-get-project-requests';
import { ResponseModelOfCreateProjectRequestResult } from '../models/response-model-of-create-project-request-result';
import { ResponseModelOfDeleteProjectRequestResult } from '../models/response-model-of-delete-project-request-result';
import { ResponseModelOfGetProjectRequestListFiltersResult } from '../models/response-model-of-get-project-request-list-filters-result';
import { ResponseModelOfGetProjectRequestListResult } from '../models/response-model-of-get-project-request-list-result';

@Injectable({ providedIn: 'root' })
export class ProjectRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectRequestGetProjectRequests()` */
  static readonly ProjectRequestGetProjectRequestsPath = '/api/Projects/ProjectRequest';

  /**
   * Returns project request/project requests according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectRequestGetProjectRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRequestGetProjectRequests$Response(params?: ProjectRequestGetProjectRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectRequestListResult>> {
    return projectRequestGetProjectRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns project request/project requests according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectRequestGetProjectRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRequestGetProjectRequests(params?: ProjectRequestGetProjectRequests$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectRequestListResult> {
    return this.projectRequestGetProjectRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectRequestListResult>): ResponseModelOfGetProjectRequestListResult => r.body)
    );
  }

  /** Path part for operation `projectRequestCreateProjectRequest()` */
  static readonly ProjectRequestCreateProjectRequestPath = '/api/Projects/ProjectRequest';

  /**
   * Returns project request created.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectRequestCreateProjectRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectRequestCreateProjectRequest$Response(params: ProjectRequestCreateProjectRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCreateProjectRequestResult>> {
    return projectRequestCreateProjectRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns project request created.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectRequestCreateProjectRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectRequestCreateProjectRequest(params: ProjectRequestCreateProjectRequest$Params, context?: HttpContext): Observable<ResponseModelOfCreateProjectRequestResult> {
    return this.projectRequestCreateProjectRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfCreateProjectRequestResult>): ResponseModelOfCreateProjectRequestResult => r.body)
    );
  }

  /** Path part for operation `projectRequestDeleteProjectRequest()` */
  static readonly ProjectRequestDeleteProjectRequestPath = '/api/Projects/ProjectRequest';

  /**
   * Returns id of project request deleted.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectRequestDeleteProjectRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRequestDeleteProjectRequest$Response(params: ProjectRequestDeleteProjectRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfDeleteProjectRequestResult>> {
    return projectRequestDeleteProjectRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns id of project request deleted.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectRequestDeleteProjectRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRequestDeleteProjectRequest(params: ProjectRequestDeleteProjectRequest$Params, context?: HttpContext): Observable<ResponseModelOfDeleteProjectRequestResult> {
    return this.projectRequestDeleteProjectRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfDeleteProjectRequestResult>): ResponseModelOfDeleteProjectRequestResult => r.body)
    );
  }

  /** Path part for operation `projectRequestGetProjectFilters()` */
  static readonly ProjectRequestGetProjectFiltersPath = '/api/Projects/ProjectRequest/filters';

  /**
   * Returns project filters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectRequestGetProjectFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRequestGetProjectFilters$Response(params: ProjectRequestGetProjectFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectRequestListFiltersResult>> {
    return projectRequestGetProjectFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns project filters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectRequestGetProjectFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRequestGetProjectFilters(params: ProjectRequestGetProjectFilters$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectRequestListFiltersResult> {
    return this.projectRequestGetProjectFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectRequestListFiltersResult>): ResponseModelOfGetProjectRequestListFiltersResult => r.body)
    );
  }

}
