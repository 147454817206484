import { OrderTypeModel, UserInfoModel, UserSortByType, UserTenantModel } from "../services/api/models";

export interface UserGetUser {
    userId?: string;
    projectId?: string;
    tenantId?: string;
    userSortbyType?: UserSortByType;
    orderType?: OrderTypeModel;
    userName?: string[];
    userEmail?: string[];
    userRole?: string[];
    recordFrom?: number;
    recordTo?: number;
}

export interface CurrentTenantUser extends Omit<UserInfoModel,"tenants"> {
    tenant: UserTenantModel;
}

// from \BE\Sources\Foundation\AiCaptain.Foundation.Authorization.Abstractions\Consts.cs
export const userRoles = [
    "PWC_ADMIN",
    "COMPANY_ADMIN",
    "PROJECT_MANAGER",
    "STANDARD_USER",
    "SPECIALIST",
    "COMPLIANCE_SPECIALIST"
]

export enum UserRoles {
    PWC_ADMIN = "PWC_ADMIN",
    COMPANY_ADMIN = "COMPANY_ADMIN",
    PROJECT_MANAGER = "PROJECT_MANAGER",
    STANDARD_USER = "STANDARD_USER",
    SPECIALIST = "SPECIALIST",
    COMPLIANCE_SPECIALIST = "COMPLIANCE_SPECIALIST",
}