/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ImportRoleApiParameters } from '../../models/import-role-api-parameters';
import { ResponseModelOfImportRoleResult } from '../../models/response-model-of-import-role-result';

export interface RoleImportRole$Params {
  
    /**
     * Parameters used to import role.
     */
    body: ImportRoleApiParameters
}

export function roleImportRole(http: HttpClient, rootUrl: string, params: RoleImportRole$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfImportRoleResult>> {
  const rb = new RequestBuilder(rootUrl, roleImportRole.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfImportRoleResult>;
    })
  );
}

roleImportRole.PATH = '/api/Roles/Roles';
