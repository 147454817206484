/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface FormAttachmentsDownloadAttachments$Params {

/**
 * Attachment IDs.
 */
  attachmentIDs?: Array<string>;
}

export function formAttachmentsDownloadAttachments(http: HttpClient, rootUrl: string, params?: FormAttachmentsDownloadAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
  const rb = new RequestBuilder(rootUrl, formAttachmentsDownloadAttachments.PATH, 'get');
  if (params) {
    rb.query('attachmentIDs', params.attachmentIDs, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Blob>;
    })
  );
}

formAttachmentsDownloadAttachments.PATH = '/api/Forms/FormAttachments/download';
