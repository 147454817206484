/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfUpdateFormDataResult } from '../../models/response-model-of-update-form-data-result';
import { UpdateFormDataApiParameters } from '../../models/update-form-data-api-parameters';

export interface FormDataUpdateFormData$Params {
  
    /**
     * Request Model for updating form instances.
     */
    body: Array<UpdateFormDataApiParameters>
}

export function formDataUpdateFormData(http: HttpClient, rootUrl: string, params: FormDataUpdateFormData$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUpdateFormDataResult>> {
  const rb = new RequestBuilder(rootUrl, formDataUpdateFormData.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfUpdateFormDataResult>;
    })
  );
}

formDataUpdateFormData.PATH = '/api/Forms/FormData';
