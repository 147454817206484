import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgxAccordionModule,
  NgxBadgeModule,
  NgxButtonModule,
  NgxFileDropModule,
  NgxInputCheckboxModule,
  NgxInputDateModule,
  NgxInputFileModule,
  NgxInputTextModule,
  NgxMenuModule,
  NgxModalModule,
  NgxPWClogoModule,
  NgxPWClogoTextModule,
  NgxPaginationModule,
  NgxRadioModule,
  NgxSelectModule,
  NgxSliderModule,
  NgxTextareaModule,
  NgxToggleModule,
  NgxInputNumberModule,
} from '@pwc/appkit-styled-lib';
import { NgxTabModule } from '@pwc/appkit-styled-lib/src/lib/component/tab';
import { NgxTooltipModule } from '@pwc/appkit-styled-lib/src/lib/directive/pop-ups/ngx-tooltip';
import { BeObjToArrayPipe } from 'src/app/pipes/be-obj-to-array.pipe';
import { BeStringToArrayPipe } from 'src/app/pipes/be-string-to-array.pipe';
import { FormFieldPopulatePipe } from 'src/app/pipes/form-field-populate.pipe';
import { HasUserRoleOnTenantPipe } from 'src/app/pipes/has-user-role-on-tenant.pipe';
import { MatrixPipe } from 'src/app/pipes/matrix.pipe';
import { PageNumberPipe } from 'src/app/pipes/page-number.pipe';
import { StringToNumberPipe } from 'src/app/pipes/string-to-number.pipe';
import { UserInitialsPipe } from 'src/app/pipes/user-initials.pipe';
import { UserProjectToRolePipe } from 'src/app/pipes/user-project-to-role.pipe';
import { VisibleFieldPipe } from 'src/app/pipes/visible-field.pipe';
import { PageItemsSelectorComponent } from './page-items-selector/page-items-selector.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { CamelCaseToHumanReadablePipe } from 'src/app/pipes/camel-case-to-human-readable.pipe';
import { userEditDisabled } from 'src/app/pipes/user-edit-disabled.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { UserEditAddPipe } from 'src/app/pipes/user-edit-add.pipe';
import { WordFormatPipe } from 'src/app/pipes/word-format.pipe';
import { FileIconPipe } from 'src/app/pipes/file-icon.pipe';

const components = [
  PageItemsSelectorComponent,
  SidePanelComponent,
];

const pipes = [
  UserProjectToRolePipe,
  userEditDisabled,
  UserEditAddPipe,
  PageNumberPipe,
  BeStringToArrayPipe,
  BeObjToArrayPipe,
  MatrixPipe,
  StringToNumberPipe,
  FormFieldPopulatePipe,
  VisibleFieldPipe,
  UserInitialsPipe,
  HasUserRoleOnTenantPipe,
  CamelCaseToHumanReadablePipe,
  DateFormatPipe,
  WordFormatPipe,
  FileIconPipe
];

const modules = [
  NgxTabModule,
  NgxTooltipModule,
  NgxModalModule,
  NgxButtonModule,
  NgxInputTextModule,
  NgxInputCheckboxModule,
  NgxTextareaModule,
  NgxSelectModule,
  NgxBadgeModule,
  NgxPaginationModule,
  NgxMenuModule,
  NgxAccordionModule,
  NgxInputDateModule,
  NgxFileDropModule,
  NgxInputFileModule,
  NgxToggleModule,
  NgxTextareaModule,
  NgxButtonModule,
  NgxRadioModule,
  NgxPWClogoTextModule,
  NgxPWClogoModule,
  NgxSliderModule,
  NgxInputNumberModule,
];

@NgModule({
  declarations: [...pipes, ...components],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...modules],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ...modules,
    ...pipes,
    ...components,
  ],
})
export class NgxCoreModule {}
