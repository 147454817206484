/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { RequestSupportFiltersResponse } from '../models/request-support-filters-response';
import { RequestSupportSectionResponseResult } from '../models/request-support-section-response-result';
import { ResponseResult } from '../models/response-result';
import { supportDownloadAttachment } from '../fn/support/support-download-attachment';
import { SupportDownloadAttachment$Params } from '../fn/support/support-download-attachment';
import { supportGetAllSupportRequests } from '../fn/support/support-get-all-support-requests';
import { SupportGetAllSupportRequests$Params } from '../fn/support/support-get-all-support-requests';
import { supportGetAllSupportRequestsFilters } from '../fn/support/support-get-all-support-requests-filters';
import { SupportGetAllSupportRequestsFilters$Params } from '../fn/support/support-get-all-support-requests-filters';
import { supportGetMySupportRequests } from '../fn/support/support-get-my-support-requests';
import { SupportGetMySupportRequests$Params } from '../fn/support/support-get-my-support-requests';
import { supportGetMySupportRequestsFilters } from '../fn/support/support-get-my-support-requests-filters';
import { SupportGetMySupportRequestsFilters$Params } from '../fn/support/support-get-my-support-requests-filters';
import { supportGetPrioritiesList } from '../fn/support/support-get-priorities-list';
import { SupportGetPrioritiesList$Params } from '../fn/support/support-get-priorities-list';
import { supportGetStatusesList } from '../fn/support/support-get-statuses-list';
import { SupportGetStatusesList$Params } from '../fn/support/support-get-statuses-list';
import { supportGetSupportTicket } from '../fn/support/support-get-support-ticket';
import { SupportGetSupportTicket$Params } from '../fn/support/support-get-support-ticket';
import { supportReOpenTicket } from '../fn/support/support-re-open-ticket';
import { SupportReOpenTicket$Params } from '../fn/support/support-re-open-ticket';
import { supportResolveTicket } from '../fn/support/support-resolve-ticket';
import { SupportResolveTicket$Params } from '../fn/support/support-resolve-ticket';
import { supportSaveComment } from '../fn/support/support-save-comment';
import { SupportSaveComment$Params } from '../fn/support/support-save-comment';
import { supportSaveSupportTicket } from '../fn/support/support-save-support-ticket';
import { SupportSaveSupportTicket$Params } from '../fn/support/support-save-support-ticket';
import { supportTakeOverTicket } from '../fn/support/support-take-over-ticket';
import { SupportTakeOverTicket$Params } from '../fn/support/support-take-over-ticket';

@Injectable({ providedIn: 'root' })
export class SupportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `supportGetAllSupportRequests()` */
  static readonly SupportGetAllSupportRequestsPath = '/api/Support/tickets/getallsupporttickets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportGetAllSupportRequests()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportGetAllSupportRequests$Response(params: SupportGetAllSupportRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<RequestSupportSectionResponseResult>> {
    return supportGetAllSupportRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportGetAllSupportRequests$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportGetAllSupportRequests(params: SupportGetAllSupportRequests$Params, context?: HttpContext): Observable<RequestSupportSectionResponseResult> {
    return this.supportGetAllSupportRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestSupportSectionResponseResult>): RequestSupportSectionResponseResult => r.body)
    );
  }

  /** Path part for operation `supportGetAllSupportRequestsFilters()` */
  static readonly SupportGetAllSupportRequestsFiltersPath = '/api/Support/tickets/getallsupporttickets/filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportGetAllSupportRequestsFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetAllSupportRequestsFilters$Response(params?: SupportGetAllSupportRequestsFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<RequestSupportFiltersResponse>> {
    return supportGetAllSupportRequestsFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportGetAllSupportRequestsFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetAllSupportRequestsFilters(params?: SupportGetAllSupportRequestsFilters$Params, context?: HttpContext): Observable<RequestSupportFiltersResponse> {
    return this.supportGetAllSupportRequestsFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestSupportFiltersResponse>): RequestSupportFiltersResponse => r.body)
    );
  }

  /** Path part for operation `supportSaveComment()` */
  static readonly SupportSaveCommentPath = '/api/Support/tickets/savecomment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportSaveComment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  supportSaveComment$Response(params?: SupportSaveComment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
    return supportSaveComment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportSaveComment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  supportSaveComment(params?: SupportSaveComment$Params, context?: HttpContext): Observable<ResponseResult> {
    return this.supportSaveComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResult>): ResponseResult => r.body)
    );
  }

  /** Path part for operation `supportGetMySupportRequests()` */
  static readonly SupportGetMySupportRequestsPath = '/api/Support/tickets/getmysupporttickets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportGetMySupportRequests()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportGetMySupportRequests$Response(params: SupportGetMySupportRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<RequestSupportSectionResponseResult>> {
    return supportGetMySupportRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportGetMySupportRequests$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportGetMySupportRequests(params: SupportGetMySupportRequests$Params, context?: HttpContext): Observable<RequestSupportSectionResponseResult> {
    return this.supportGetMySupportRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestSupportSectionResponseResult>): RequestSupportSectionResponseResult => r.body)
    );
  }

  /** Path part for operation `supportGetMySupportRequestsFilters()` */
  static readonly SupportGetMySupportRequestsFiltersPath = '/api/Support/tickets/getmysupporttickets/filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportGetMySupportRequestsFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetMySupportRequestsFilters$Response(params?: SupportGetMySupportRequestsFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<RequestSupportFiltersResponse>> {
    return supportGetMySupportRequestsFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportGetMySupportRequestsFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetMySupportRequestsFilters(params?: SupportGetMySupportRequestsFilters$Params, context?: HttpContext): Observable<RequestSupportFiltersResponse> {
    return this.supportGetMySupportRequestsFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestSupportFiltersResponse>): RequestSupportFiltersResponse => r.body)
    );
  }

  /** Path part for operation `supportSaveSupportTicket()` */
  static readonly SupportSaveSupportTicketPath = '/api/Support/tickets/saveticket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportSaveSupportTicket()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  supportSaveSupportTicket$Response(params?: SupportSaveSupportTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
    return supportSaveSupportTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportSaveSupportTicket$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  supportSaveSupportTicket(params?: SupportSaveSupportTicket$Params, context?: HttpContext): Observable<ResponseResult> {
    return this.supportSaveSupportTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResult>): ResponseResult => r.body)
    );
  }

  /** Path part for operation `supportGetSupportTicket()` */
  static readonly SupportGetSupportTicketPath = '/api/Support/ticket/getticket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportGetSupportTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetSupportTicket$Response(params?: SupportGetSupportTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<RequestSupportSectionResponseResult>> {
    return supportGetSupportTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportGetSupportTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetSupportTicket(params?: SupportGetSupportTicket$Params, context?: HttpContext): Observable<RequestSupportSectionResponseResult> {
    return this.supportGetSupportTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestSupportSectionResponseResult>): RequestSupportSectionResponseResult => r.body)
    );
  }

  /** Path part for operation `supportTakeOverTicket()` */
  static readonly SupportTakeOverTicketPath = '/api/Support/ticket/takeover';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportTakeOverTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportTakeOverTicket$Response(params: SupportTakeOverTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
    return supportTakeOverTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportTakeOverTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportTakeOverTicket(params: SupportTakeOverTicket$Params, context?: HttpContext): Observable<ResponseResult> {
    return this.supportTakeOverTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResult>): ResponseResult => r.body)
    );
  }

  /** Path part for operation `supportReOpenTicket()` */
  static readonly SupportReOpenTicketPath = '/api/Support/ticket/reopen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportReOpenTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportReOpenTicket$Response(params: SupportReOpenTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
    return supportReOpenTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportReOpenTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportReOpenTicket(params: SupportReOpenTicket$Params, context?: HttpContext): Observable<ResponseResult> {
    return this.supportReOpenTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResult>): ResponseResult => r.body)
    );
  }

  /** Path part for operation `supportResolveTicket()` */
  static readonly SupportResolveTicketPath = '/api/Support/ticket/resolve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportResolveTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportResolveTicket$Response(params: SupportResolveTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
    return supportResolveTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportResolveTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportResolveTicket(params: SupportResolveTicket$Params, context?: HttpContext): Observable<ResponseResult> {
    return this.supportResolveTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResult>): ResponseResult => r.body)
    );
  }

  /** Path part for operation `supportGetPrioritiesList()` */
  static readonly SupportGetPrioritiesListPath = '/api/Support/prioritieslist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportGetPrioritiesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetPrioritiesList$Response(params?: SupportGetPrioritiesList$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
    return supportGetPrioritiesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportGetPrioritiesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetPrioritiesList(params?: SupportGetPrioritiesList$Params, context?: HttpContext): Observable<ResponseResult> {
    return this.supportGetPrioritiesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResult>): ResponseResult => r.body)
    );
  }

  /** Path part for operation `supportGetStatusesList()` */
  static readonly SupportGetStatusesListPath = '/api/Support/statuseslist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportGetStatusesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetStatusesList$Response(params?: SupportGetStatusesList$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
    return supportGetStatusesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportGetStatusesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportGetStatusesList(params?: SupportGetStatusesList$Params, context?: HttpContext): Observable<ResponseResult> {
    return this.supportGetStatusesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResult>): ResponseResult => r.body)
    );
  }

  /** Path part for operation `supportDownloadAttachment()` */
  static readonly SupportDownloadAttachmentPath = '/api/Support/download/{supportAttachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportDownloadAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportDownloadAttachment$Response(params: SupportDownloadAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return supportDownloadAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportDownloadAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  supportDownloadAttachment(params: SupportDownloadAttachment$Params, context?: HttpContext): Observable<Blob> {
    return this.supportDownloadAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
