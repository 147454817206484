/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { projectCompanyAccessGetProjectCompanyAccess } from '../fn/project-company-access/project-company-access-get-project-company-access';
import { ProjectCompanyAccessGetProjectCompanyAccess$Params } from '../fn/project-company-access/project-company-access-get-project-company-access';
import { ResponseModelOfGetProjectCompanyAccessResult } from '../models/response-model-of-get-project-company-access-result';

@Injectable({ providedIn: 'root' })
export class ProjectCompanyAccessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectCompanyAccessGetProjectCompanyAccess()` */
  static readonly ProjectCompanyAccessGetProjectCompanyAccessPath = '/api/Projects/CompanyAccess';

  /**
   * Returns information about company wide roles in the given tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectCompanyAccessGetProjectCompanyAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectCompanyAccessGetProjectCompanyAccess$Response(params: ProjectCompanyAccessGetProjectCompanyAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectCompanyAccessResult>> {
    return projectCompanyAccessGetProjectCompanyAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns information about company wide roles in the given tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectCompanyAccessGetProjectCompanyAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectCompanyAccessGetProjectCompanyAccess(params: ProjectCompanyAccessGetProjectCompanyAccess$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectCompanyAccessResult> {
    return this.projectCompanyAccessGetProjectCompanyAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectCompanyAccessResult>): ResponseModelOfGetProjectCompanyAccessResult => r.body)
    );
  }

}
