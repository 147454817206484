/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { formDataGetFormData } from '../fn/form-data/form-data-get-form-data';
import { FormDataGetFormData$Params } from '../fn/form-data/form-data-get-form-data';
import { formDataUpdateFormData } from '../fn/form-data/form-data-update-form-data';
import { FormDataUpdateFormData$Params } from '../fn/form-data/form-data-update-form-data';
import { ResponseModelOfGetFormDataResult } from '../models/response-model-of-get-form-data-result';
import { ResponseModelOfUpdateFormDataResult } from '../models/response-model-of-update-form-data-result';

@Injectable({ providedIn: 'root' })
export class FormDataService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formDataGetFormData()` */
  static readonly FormDataGetFormDataPath = '/api/Forms/FormData';

  /**
   * Returns form data or datas according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formDataGetFormData()` instead.
   *
   * This method doesn't expect any request body.
   */
  formDataGetFormData$Response(params: FormDataGetFormData$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormDataResult>> {
    return formDataGetFormData(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns form data or datas according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formDataGetFormData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formDataGetFormData(params: FormDataGetFormData$Params, context?: HttpContext): Observable<ResponseModelOfGetFormDataResult> {
    return this.formDataGetFormData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetFormDataResult>): ResponseModelOfGetFormDataResult => r.body)
    );
  }

  /** Path part for operation `formDataUpdateFormData()` */
  static readonly FormDataUpdateFormDataPath = '/api/Forms/FormData';

  /**
   * Update or creates a new version of form (when user edits and saves form).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formDataUpdateFormData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formDataUpdateFormData$Response(params: FormDataUpdateFormData$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUpdateFormDataResult>> {
    return formDataUpdateFormData(this.http, this.rootUrl, params, context);
  }

  /**
   * Update or creates a new version of form (when user edits and saves form).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formDataUpdateFormData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formDataUpdateFormData(params: FormDataUpdateFormData$Params, context?: HttpContext): Observable<ResponseModelOfUpdateFormDataResult> {
    return this.formDataUpdateFormData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfUpdateFormDataResult>): ResponseModelOfUpdateFormDataResult => r.body)
    );
  }

}
