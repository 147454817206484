import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, interval, map, shareReplay, startWith, switchMap } from 'rxjs';
import { UserInfoModel, UserTenantModel } from './api/models';
import { UserService } from './api/services';
import { CurrentTenantUser } from '../models/user-get-user.model';

@Injectable({
  providedIn: 'root',
})
export class SharedUserService {

  storageTenant(): UserTenantModel | null {
    const storageTenant = localStorage.getItem('tenant');
    if (storageTenant) {
      return JSON.parse(storageTenant);
    }
    return null
  };

  storageUser(): UserInfoModel | null {
    const storageUser = localStorage.getItem('simulatedUser');
    if (storageUser) {
      return JSON.parse(storageUser);
    }
    return null
  };

  private intervalDuration = 120;
  private interval$ = interval(this.intervalDuration * 1000).pipe(
    startWith(0)
  );

  constructor(private userService: UserService) {}
  
  private tenant = new BehaviorSubject<UserTenantModel>(null);
  private user = new BehaviorSubject<UserInfoModel>(null);
  updateTenantList = new BehaviorSubject<void>(null);
  tenantList$ = this.updateTenantList.asObservable().pipe(
    switchMap(data => this.userService.userGetUserInfo().pipe(
      map(d => d.result.user.tenants)
    ))
  )

  private currentTenantUser = new BehaviorSubject<CurrentTenantUser>(null);
  currentTenantUserValue = this.currentTenantUser;
  currentTenantUser$: Observable<CurrentTenantUser> = this.currentTenantUser.asObservable().pipe(
    filter(v => !!v)
  );
  getCurrentTenantUser = () => this.currentTenantUser.getValue();

  private currentTenantUserTenants = new BehaviorSubject<UserTenantModel[]>(null); 
  currentTenantUserTenants$ = this.currentTenantUserTenants.asObservable(); 

  clearSimulatedUser() {
    localStorage.removeItem('simulatedUser');
    localStorage.removeItem('tenant'); // simulated user might have stored its own tenant
    location.reload();
  }


  updateUser() {
    const tenantId = this.currentTenantUser.getValue().tenant.id;
    this.userService.userGetUserInfo({tenantId: tenantId}).pipe(map(d => d.result.user)).subscribe(user => {
      localStorage.removeItem('simulatedUser');
      localStorage.setItem('simulatedUser',JSON.stringify(user));
      this.user.next(user);
        let currentTenantId;
        let foundTenant;
        if (this.tenant.getValue()) {
          currentTenantId = this.tenant.getValue().id;
          foundTenant = user.tenants.find(t => t.id === currentTenantId);
        }
        
        const storageTenant = this.storageTenant();
        if (storageTenant) {
          this.tenant.next(storageTenant);
          foundTenant = storageTenant;
        } else {
          foundTenant = user.tenants[0];
        }
        this.currentTenantUserTenants.next(user.tenants);
        const { tenants, ...userWithoutTenants } = user;
        if (foundTenant) {
          localStorage.setItem('tenant',JSON.stringify(foundTenant));
          this.currentTenantUser.next({
            tenant: foundTenant,
            ...userWithoutTenants
          })
        } else {
          throw `User ${user.name} - ${user.id} does not have the selected tenant. Selection list is wrong. Or user is wrong`
        }
    })
  }

  changeUser(user?: Omit<UserInfoModel,"tenants">, tenant?: UserTenantModel,fnc?: Function[]) {
    localStorage.removeItem('simulatedUser');
    localStorage.removeItem('tenant'); // simulated user might have stored its own tenant
    if (user) {
      localStorage.setItem('simulatedUser',JSON.stringify(user));
    }

    let obs;
    if (tenant) {
      obs = this.userService.userGetUserInfo({tenantId: tenant.id}).pipe(map(d => d.result.user))
    } else {
      obs = this.userService.userGetUserInfo().pipe(
        map(d => d.result.user),
        switchMap(d => this.userService.userGetUserInfo({tenantId: d.tenants[0].id}).pipe(map(d => d.result.user)))
      )
    }
    obs.subscribe(user => {
        this.user.next(user);
        let currentTenantId;
        let foundTenant;
        if (this.tenant.getValue()) {
          currentTenantId = this.tenant.getValue().id;
          foundTenant = user.tenants.find(t => t.id === currentTenantId);
        }
        
        const storageTenant = this.storageTenant();
        if (storageTenant) {
          this.tenant.next(storageTenant);
          foundTenant = storageTenant;
        } else {
          foundTenant = user.tenants[0];
        }
        this.currentTenantUserTenants.next(user.tenants);
        const { tenants, ...userWithoutTenants } = user;
        if (foundTenant) {
          localStorage.setItem('tenant',JSON.stringify(foundTenant));
          this.currentTenantUser.next({
            tenant: foundTenant,
            ...userWithoutTenants
          })
        } else {
          throw `User ${user.name} - ${user.id} does not have the selected tenant. Selection list is wrong. Or user is wrong`
        }
        fnc?.forEach(f => f())
      }
    )
  }

  changeTenant(tenant: UserTenantModel) {
    this.userService.userGetUserInfo({tenantId: tenant.id}).pipe(
      map(d => d.result.user),
    ).subscribe(
      user => {
        const tenantWithRoles = user.tenants.find(t => t.id === tenant.id);
        const { tenants, ...userWithoutTenants } = user;
        if (tenantWithRoles) {
          localStorage.setItem('tenant',JSON.stringify(tenantWithRoles));
          localStorage.setItem('simulatedUser',JSON.stringify(user));
          this.tenant.next(tenantWithRoles);
          this.currentTenantUser.next({
            tenant: tenantWithRoles,
            ...userWithoutTenants
          })
        } else {
          throw `User ${user.name} - ${user.id} does not have the selected tenant. Selection list is wrong. Or user is wrong`
        }
      }
    );
  }

}
