import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableProdMode, isDevMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { GetClientConfigurationModel } from './app/services/api/models';
import { environment } from './environments/environment';

(async () => {
  if (isDevMode()) {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  } else {
    let appName = null;
    if (window.location.pathname.includes('AI_Captain-Dev-FE'))
      appName = 'AI_Captain-Dev-FE';
    else {
      if (window.location.pathname.includes('AI_Captain-Test-FE'))
        appName = 'AI_Captain-Test-FE';
    }

    await fetch(
      appName != null
        ? `/${appName}/api/AppConfiguration/Configuration`
        : '/api/AppConfiguration/Configuration'
    )
      .then((res) => res.json())
      .then((res: any) => {
        let configuration = res.result
          .clientConfiguration as GetClientConfigurationModel;
        environment.apiRootUrl = configuration.apiRootUrl;
        environment.clientId = configuration.authClientId;
        environment.oauth2Issuer = configuration.authIssuerUrl;
        environment.redirectUri = configuration.authRedirectUrl;
        environment.baseUrl = configuration.serverBaseUrl;
      });

    enableProdMode();

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  }
})();
