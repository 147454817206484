/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfGetFormsVersionListResult } from '../../models/response-model-of-get-forms-version-list-result';

export interface FormVersionsGetFormVersions$Params {

/**
 * ID of the requested form instance(s).
 */
  formInstanceId?: string;

/**
 * The starting position to get records.
 */
  recordFrom?: number | null;

/**
 * Gets records until this position.
 */
  recordTo?: number | null;
}

export function formVersionsGetFormVersions(http: HttpClient, rootUrl: string, params?: FormVersionsGetFormVersions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormsVersionListResult>> {
  const rb = new RequestBuilder(rootUrl, formVersionsGetFormVersions.PATH, 'get');
  if (params) {
    rb.query('formInstanceId', params.formInstanceId, {});
    rb.query('recordFrom', params.recordFrom, {});
    rb.query('recordTo', params.recordTo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetFormsVersionListResult>;
    })
  );
}

formVersionsGetFormVersions.PATH = '/api/Forms/FormVersions';
