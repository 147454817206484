/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfUpdateFormAttachmentResult } from '../../models/response-model-of-update-form-attachment-result';

export interface FormAttachmentsUpdateFormAttachment$Params {
      body?: {
'FormAttachmentId'?: string;
'AttachmentType'?: string | null;
'Link'?: string | null;
'File'?: Blob | null;
'Name'?: string | null;
'Description'?: string | null;
}
}

export function formAttachmentsUpdateFormAttachment(http: HttpClient, rootUrl: string, params?: FormAttachmentsUpdateFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUpdateFormAttachmentResult>> {
  const rb = new RequestBuilder(rootUrl, formAttachmentsUpdateFormAttachment.PATH, 'put');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfUpdateFormAttachmentResult>;
    })
  );
}

formAttachmentsUpdateFormAttachment.PATH = '/api/Forms/FormAttachments';
