import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, delay, delayWhen, filter, interval, map, merge, of, pairwise, share, switchMap, tap } from 'rxjs';
import { HttpActivityIndication } from '../models/http-activity-indication.model';

@Injectable({
  providedIn: 'root'
})
export class HttpActivityIndicationService {

  minimumDuration = 700;
  events$ = new BehaviorSubject<HttpActivityIndication[]>([]);
  counter$: Observable<number> = this.events$.pipe(
    map(v => v.filter(event => !event.completed)),
    map(v => v.length),
  );
  counterStart$: Observable<Date> = this.counter$.pipe(
    pairwise(),
    filter(v => {
      const previous = v[0];
      const current = v[1];
      return current === 1 && previous === 0
    }),
    map(() => new Date()),
  );
  counterEnd$: Observable<Date | null> = this.counter$.pipe(
    pairwise(),
    filter(v => {
      const previous = v[0];
      const current = v[1];
      return current === 0 && previous === 1
    }),
    map(() => new Date()),
  );

  counterEndDelayed$ = merge(
    of(new Date()),
    this.counterStart$.pipe(
      switchMap(startTime => this.fewfew(startTime))
    )
  ) 

  fewfew(startTime: Date): Observable<Date> {
    return this.counterEnd$.pipe(
      map(counterEnd => {
        const q = this.msBetweenDates(startTime,new Date());
        return q
      }),
      delayWhen(duration => {
        if (this.minimumDuration < duration) {
          return interval(0) 
        } else {
          return interval(this.minimumDuration - duration)
        }
      }),
      map(duration => new Date())
    )
  }

  loading$: Observable<boolean> = combineLatest([this.counterStart$,this.counterEndDelayed$]).pipe(
    map(data => {
      return {start: data[0], end: data[1]}
    }),
    map(data => {
      const loading = data.end < data.start;
      return loading
    }),
    delay(0)
  )

  msBetweenDates(date1: Date, date2: Date): number {
    const diffMs: number = date2.getTime() - date1.getTime();
    return Math.abs(diffMs);
  }
}
