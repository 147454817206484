/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { riskTypesImportRiskTypes } from '../fn/risk-types/risk-types-import-risk-types';
import { RiskTypesImportRiskTypes$Params } from '../fn/risk-types/risk-types-import-risk-types';

@Injectable({ providedIn: 'root' })
export class RiskTypesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `riskTypesImportRiskTypes()` */
  static readonly RiskTypesImportRiskTypesPath = '/api/RiskAssesment/RiskTypes';

  /**
   * Import risk types and its evaluatiion expressions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riskTypesImportRiskTypes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  riskTypesImportRiskTypes$Response(params: RiskTypesImportRiskTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return riskTypesImportRiskTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Import risk types and its evaluatiion expressions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `riskTypesImportRiskTypes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  riskTypesImportRiskTypes(params: RiskTypesImportRiskTypes$Params, context?: HttpContext): Observable<void> {
    return this.riskTypesImportRiskTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
