/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrderTypeModel } from '../../models/order-type-model';
import { ProjectRequestOrderByType } from '../../models/project-request-order-by-type';
import { ProjectRequestStatusType } from '../../models/project-request-status-type';
import { ResponseModelOfGetProjectRequestListResult } from '../../models/response-model-of-get-project-request-list-result';

export interface ProjectRequestGetProjectRequests$Params {

/**
 * Gets or sets a requested project.
 */
  projectId?: string | null;

/**
 * Gets or sets a requested tenant.
 */
  tenantId?: string;

/**
 * Gets or sets project sort type.
 */
  orderByType?: ProjectRequestOrderByType;

/**
 * Gets or sets project sort type.
 */
  sortBy?: OrderTypeModel;

/**
 * Gets or sets project status.
 */
  projectRequestStatusType?: ProjectRequestStatusType;

/**
 * Gets or sets list of project's name requested.
 */
  projectName?: Array<string> | null;

/**
 * Gets or sets list of project's manager requested.
 */
  projectManager?: Array<string> | null;

/**
 * Gets or sets a list of requestors to be used for filtering.
 */
  projectRequestor?: Array<string> | null;

/**
 * Gets or sets a value indicating whether only projects where current is participationg are returned.
 */
  currentUserOnly?: boolean;

/**
 * Gets or sets a requested recordFrom.
 */
  recordFrom?: number | null;

/**
 * Gets or sets requested recordFrom.
 */
  recordTo?: number | null;
}

export function projectRequestGetProjectRequests(http: HttpClient, rootUrl: string, params?: ProjectRequestGetProjectRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectRequestListResult>> {
  const rb = new RequestBuilder(rootUrl, projectRequestGetProjectRequests.PATH, 'get');
  if (params) {
    rb.query('projectId', params.projectId, {});
    rb.query('tenantId', params.tenantId, {});
    rb.query('orderByType', params.orderByType, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('projectRequestStatusType', params.projectRequestStatusType, {});
    rb.query('projectName', params.projectName, {"style":"form","explode":true});
    rb.query('projectManager', params.projectManager, {"style":"form","explode":true});
    rb.query('projectRequestor', params.projectRequestor, {"style":"form","explode":true});
    rb.query('currentUserOnly', params.currentUserOnly, {});
    rb.query('recordFrom', params.recordFrom, {});
    rb.query('recordTo', params.recordTo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetProjectRequestListResult>;
    })
  );
}

projectRequestGetProjectRequests.PATH = '/api/Projects/ProjectRequest';
