/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfGetFormDefinitionResult } from '../../models/response-model-of-get-form-definition-result';

export interface FormDefinitionGetFormDefinition$Params {

/**
 * ID of the requested form definition(s). If definitionCode is not provided, retuns all known form definitions.
 */
  definitionCode?: string;

/**
 * ID of the project to be used as a reference for downloading of data of form definition. If not provided, latest version will be returned. If provided, version of formdefintion will be returned in version valid in the time of project creation.
 */
  projectId?: string | null;
}

export function formDefinitionGetFormDefinition(http: HttpClient, rootUrl: string, params?: FormDefinitionGetFormDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormDefinitionResult>> {
  const rb = new RequestBuilder(rootUrl, formDefinitionGetFormDefinition.PATH, 'get');
  if (params) {
    rb.query('definitionCode', params.definitionCode, {});
    rb.query('projectId', params.projectId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetFormDefinitionResult>;
    })
  );
}

formDefinitionGetFormDefinition.PATH = '/api/Forms/FormDefinition';
