import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidePanelComponent {
  @Output() close = new EventEmitter<void>();
  @Input() heading: string;
}
