import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoggedOutComponent } from './modules/logged-out/logged-out.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { termsAndConditionsGuard } from './guards/terms-and-conditions.guard';
import { DeclineLogOutComponent } from './modules/decline-log-out/decline-log-out.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: `homepage`,
    pathMatch: 'full',
  },
  {
    path: 'homepage',
    loadChildren: () =>
      import('./modules/homepage/projects.module').then(
        (m) => m.ProjectsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./modules/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
    canActivate: [AuthGuard, termsAndConditionsGuard],
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./modules/support2/support2.module').then(
        (m) => m.Support2Module
      ),
      canActivate: [AuthGuard, termsAndConditionsGuard]
  },
  {
    path: 'project/:projectId',
    loadChildren: () =>
      import('./modules/project-view/project-homepage-view.module').then(
        (m) => m.ProjectHomepageViewModule
      ),
    canActivate: [AuthGuard, termsAndConditionsGuard],
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
  },
  {
    path: 'decline-logged-out',
    component: DeclineLogOutComponent,
  },
  { path: 'oidc-callback', component: CallbackComponent }, // does nothing but setting up auth
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
