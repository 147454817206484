/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { conformityAssesmentGetConformityAssesment } from '../fn/conformity-assesment/conformity-assesment-get-conformity-assesment';
import { ConformityAssesmentGetConformityAssesment$Params } from '../fn/conformity-assesment/conformity-assesment-get-conformity-assesment';
import { ResponseModelOfGetProjectConformityAssesmentResult } from '../models/response-model-of-get-project-conformity-assesment-result';

@Injectable({ providedIn: 'root' })
export class ConformityAssesmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `conformityAssesmentGetConformityAssesment()` */
  static readonly ConformityAssesmentGetConformityAssesmentPath = '/api/Projects/ConformityAssesment';

  /**
   * Returns conformity assesment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conformityAssesmentGetConformityAssesment()` instead.
   *
   * This method doesn't expect any request body.
   */
  conformityAssesmentGetConformityAssesment$Response(params: ConformityAssesmentGetConformityAssesment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectConformityAssesmentResult>> {
    return conformityAssesmentGetConformityAssesment(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns conformity assesment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conformityAssesmentGetConformityAssesment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conformityAssesmentGetConformityAssesment(params: ConformityAssesmentGetConformityAssesment$Params, context?: HttpContext): Observable<ResponseModelOfGetProjectConformityAssesmentResult> {
    return this.conformityAssesmentGetConformityAssesment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetProjectConformityAssesmentResult>): ResponseModelOfGetProjectConformityAssesmentResult => r.body)
    );
  }

}
