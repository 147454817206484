/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { copyFormVersionCopyFormVersion } from '../fn/copy-form-version/copy-form-version-copy-form-version';
import { CopyFormVersionCopyFormVersion$Params } from '../fn/copy-form-version/copy-form-version-copy-form-version';
import { ResponseModelOfCopyFormVersionResult } from '../models/response-model-of-copy-form-version-result';

@Injectable({ providedIn: 'root' })
export class CopyFormVersionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `copyFormVersionCopyFormVersion()` */
  static readonly CopyFormVersionCopyFormVersionPath = '/api/Forms/CopyFormVersion';

  /**
   * Returns copied form versions according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyFormVersionCopyFormVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyFormVersionCopyFormVersion$Response(params: CopyFormVersionCopyFormVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCopyFormVersionResult>> {
    return copyFormVersionCopyFormVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns copied form versions according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyFormVersionCopyFormVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyFormVersionCopyFormVersion(params: CopyFormVersionCopyFormVersion$Params, context?: HttpContext): Observable<ResponseModelOfCopyFormVersionResult> {
    return this.copyFormVersionCopyFormVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfCopyFormVersionResult>): ResponseModelOfCopyFormVersionResult => r.body)
    );
  }

}
