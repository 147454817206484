/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ResponseModelOfGetRolesResult } from '../models/response-model-of-get-roles-result';
import { ResponseModelOfImportRoleResult } from '../models/response-model-of-import-role-result';
import { roleGetRoles } from '../fn/role/role-get-roles';
import { RoleGetRoles$Params } from '../fn/role/role-get-roles';
import { roleImportRole } from '../fn/role/role-import-role';
import { RoleImportRole$Params } from '../fn/role/role-import-role';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `roleGetRoles()` */
  static readonly RoleGetRolesPath = '/api/Roles/Roles';

  /**
   * Returns Id and description for the roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleGetRoles$Response(params?: RoleGetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetRolesResult>> {
    return roleGetRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns Id and description for the roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roleGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleGetRoles(params?: RoleGetRoles$Params, context?: HttpContext): Observable<ResponseModelOfGetRolesResult> {
    return this.roleGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetRolesResult>): ResponseModelOfGetRolesResult => r.body)
    );
  }

  /** Path part for operation `roleImportRole()` */
  static readonly RoleImportRolePath = '/api/Roles/Roles';

  /**
   * Imports role and returns result of the operation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleImportRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleImportRole$Response(params: RoleImportRole$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfImportRoleResult>> {
    return roleImportRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports role and returns result of the operation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roleImportRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleImportRole(params: RoleImportRole$Params, context?: HttpContext): Observable<ResponseModelOfImportRoleResult> {
    return this.roleImportRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfImportRoleResult>): ResponseModelOfImportRoleResult => r.body)
    );
  }

}
