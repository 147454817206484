import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
    private oidcSecurityServices: OidcSecurityService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiRootUrl)) {
      const XSRF_Token = this.tokenExtractor.getToken();
      return this.oidcSecurityServices.getAccessToken().pipe(
        switchMap((token) => {
          let newRequest;
          if (
            (request.method === 'PUT' ||
              request.method === 'POST' ||
              request.method === 'DELETE') &&
            XSRF_Token
          ) {
            newRequest = request.clone({
              headers: request.headers
                .set('Authorization', `Bearer ${token}`)
                .set('X-XSRF-TOKEN', XSRF_Token),
            });
          } else {
            newRequest = request.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`),
            });
          }
          const simulatedUser = localStorage.getItem('simulatedUser');
          if (simulatedUser !== null) {
            const email = JSON.parse(simulatedUser).email;
            newRequest = newRequest.clone({
              headers: newRequest.headers.append('X-SimulatedUser', email),
            });
          }
          return next.handle(newRequest);
        })
      );
    }
    return next.handle(request);
  }
}
