import { Pipe, PipeTransform } from '@angular/core';
import { UserInfoModel } from '../services/api/models';
import { CurrentTenantUser, UserRoles } from '../models/user-get-user.model';

@Pipe({
  name: 'userEditAdd'
})
export class UserEditAddPipe implements PipeTransform {

  transform(user: UserInfoModel, projectId: string, currentTenantUser: CurrentTenantUser ): boolean {
    const myTenantRoles = currentTenantUser.tenant.roles;
    const currentUserRolesOnThisProject = currentTenantUser.projects.find(p => p.id === projectId)?.roles

    if (myTenantRoles.find(r => r.roleId === UserRoles.COMPANY_ADMIN || r.roleId === UserRoles.PWC_ADMIN)) { // company admin and pwc admin can do it all
      return false
    }
    if (!currentUserRolesOnThisProject) { // current user has no role on this project
      return true
    }
    if (currentUserRolesOnThisProject.find(r => r.roleId === UserRoles.PROJECT_MANAGER)) { // current user is project manager and the targeted user is not the same person
      return false
    }
    return true;
  }
}
