/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { formDefinitionGetFormDefinition } from '../fn/form-definition/form-definition-get-form-definition';
import { FormDefinitionGetFormDefinition$Params } from '../fn/form-definition/form-definition-get-form-definition';
import { formDefinitionImportFormDefinition } from '../fn/form-definition/form-definition-import-form-definition';
import { FormDefinitionImportFormDefinition$Params } from '../fn/form-definition/form-definition-import-form-definition';
import { ResponseModelOfGetFormDefinitionResult } from '../models/response-model-of-get-form-definition-result';

@Injectable({ providedIn: 'root' })
export class FormDefinitionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formDefinitionGetFormDefinition()` */
  static readonly FormDefinitionGetFormDefinitionPath = '/api/Forms/FormDefinition';

  /**
   * Returns form definition or definitions according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formDefinitionGetFormDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  formDefinitionGetFormDefinition$Response(params?: FormDefinitionGetFormDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormDefinitionResult>> {
    return formDefinitionGetFormDefinition(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns form definition or definitions according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formDefinitionGetFormDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formDefinitionGetFormDefinition(params?: FormDefinitionGetFormDefinition$Params, context?: HttpContext): Observable<ResponseModelOfGetFormDefinitionResult> {
    return this.formDefinitionGetFormDefinition$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetFormDefinitionResult>): ResponseModelOfGetFormDefinitionResult => r.body)
    );
  }

  /** Path part for operation `formDefinitionImportFormDefinition()` */
  static readonly FormDefinitionImportFormDefinitionPath = '/api/Forms/FormDefinition';

  /**
   * Imports form definition and returns result of the operation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formDefinitionImportFormDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formDefinitionImportFormDefinition$Response(params: FormDefinitionImportFormDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return formDefinitionImportFormDefinition(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports form definition and returns result of the operation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formDefinitionImportFormDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formDefinitionImportFormDefinition(params: FormDefinitionImportFormDefinition$Params, context?: HttpContext): Observable<void> {
    return this.formDefinitionImportFormDefinition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
