<div class="fixed flex xl:flex-row flex-col top-0 left-0 right-0 bottom-0 dark:bg-neutral-800 bg-white z-50" *ngIf="theme$ | async as theme">
  <div class="xl:w-1/2 w-full h-full p-8 flex flex-col">
    <div class="h-16 flex items-center">
      <pwc-logo [lightType]="lightType" [darkType]="darkType" height="60px"></pwc-logo>
    </div>
    <div class="flex items-center justify-center m-auto">
      <div class="p-12 h-full flex flex-col gap-4 items-center">
        <span class="block opacity-70 text-left w-full">AI Compliance tool</span>
        <h1 class="text-xl text-left sm:text-5xl">You have been<br> successfully logged out.</h1>
        <ngx-button (click)="logIn()" class="w-full" cssClass="w-full">Login</ngx-button>
      </div>
    </div>
    <div class="h-16"></div>
  </div>
  <div class="bg-primary xl:w-1/2 w-full h-full flex flex-col justify-center p-8">
    <div class="mx-auto">
      <h2 class="text-4xl leading-10 text-left">Start your approval process!</h2>
      <div class="text-xl leading-6 mt-8">Manage the risks and legal requirements with AI Compliance Tool effectively.</div>
    </div>
    <img src="./assets/AICT-cover-banner-{{theme}}_fitsize.png" alt="image" class="mx-auto" style="width: 678px; height: 358px;">
  </div>
</div>