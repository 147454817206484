/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AttachmentType } from '../../models/attachment-type';
import { AttachmentTypeRequest } from '../../models/attachment-type-request';
import { FormAttachmentSortByType } from '../../models/form-attachment-sort-by-type';
import { OrderTypeModel } from '../../models/order-type-model';
import { ResponseModelOfGetFormAttachmentListResult } from '../../models/response-model-of-get-form-attachment-list-result';

export interface FormAttachmentsGetFormAttachment$Params {

/**
 * Gets or sets a requested form attachment.
 */
  formAttachmentId?: string | null;

/**
 * Gets or sets a requested project.
 */
  projectId?: string | null;

/**
 * Gets or sets a requested module.
 */
  module?: Array<string> | null;

/**
 * Gets or sets a requested status.
 */
  section?: Array<string> | null;

/**
 * Gets or sets attachment filename.
 */
  name?: Array<string> | null;

/**
 * Gets or sets attachment name.
 */
  attachmentName?: Array<string> | null;

/**
 * Gets or sets uploader name.
 */
  uploader?: Array<string> | null;

/**
 * Gets or sets form attachment sort type.
 */
  formAttachmentSortByType?: FormAttachmentSortByType | null;

/**
 * Gets or sets a type of requested attachments.
 */
  attachmentTypes?: Array<AttachmentType> | null;

/**
 * Gets or sets form attachment order type.
 */
  orderType?: OrderTypeModel;

/**
 * Gets or sets type of attachments to be returned.
 */
  Type?: AttachmentTypeRequest;

/**
 * Gets or sets a requested recordFrom.
 */
  recordFrom?: number | null;

/**
 * Gets or sets requested recordFrom.
 */
  recordTo?: number | null;
}

export function formAttachmentsGetFormAttachment(http: HttpClient, rootUrl: string, params?: FormAttachmentsGetFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormAttachmentListResult>> {
  const rb = new RequestBuilder(rootUrl, formAttachmentsGetFormAttachment.PATH, 'get');
  if (params) {
    rb.query('formAttachmentId', params.formAttachmentId, {});
    rb.query('projectId', params.projectId, {});
    rb.query('module', params.module, {"style":"form","explode":true});
    rb.query('section', params.section, {"style":"form","explode":true});
    rb.query('name', params.name, {"style":"form","explode":true});
    rb.query('attachmentName', params.attachmentName, {"style":"form","explode":true});
    rb.query('uploader', params.uploader, {"style":"form","explode":true});
    rb.query('formAttachmentSortByType', params.formAttachmentSortByType, {});
    rb.query('attachmentTypes', params.attachmentTypes, {"style":"form","explode":true});
    rb.query('orderType', params.orderType, {});
    rb.query('Type', params.Type, {});
    rb.query('recordFrom', params.recordFrom, {});
    rb.query('recordTo', params.recordTo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetFormAttachmentListResult>;
    })
  );
}

formAttachmentsGetFormAttachment.PATH = '/api/Forms/FormAttachments';
