/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { templateDefinitionImportTemplateDefinition } from '../fn/template-definition/template-definition-import-template-definition';
import { TemplateDefinitionImportTemplateDefinition$Params } from '../fn/template-definition/template-definition-import-template-definition';

@Injectable({ providedIn: 'root' })
export class TemplateDefinitionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `templateDefinitionImportTemplateDefinition()` */
  static readonly TemplateDefinitionImportTemplateDefinitionPath = '/api/Template/TemplateDefinition';

  /**
   * Imports template definition and returns result of the operation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateDefinitionImportTemplateDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateDefinitionImportTemplateDefinition$Response(params: TemplateDefinitionImportTemplateDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return templateDefinitionImportTemplateDefinition(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports template definition and returns result of the operation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateDefinitionImportTemplateDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateDefinitionImportTemplateDefinition(params: TemplateDefinitionImportTemplateDefinition$Params, context?: HttpContext): Observable<void> {
    return this.templateDefinitionImportTemplateDefinition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
