/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { formAttachmentsDeleteFormAttachment } from '../fn/form-attachments/form-attachments-delete-form-attachment';
import { FormAttachmentsDeleteFormAttachment$Params } from '../fn/form-attachments/form-attachments-delete-form-attachment';
import { formAttachmentsDownloadAttachment } from '../fn/form-attachments/form-attachments-download-attachment';
import { FormAttachmentsDownloadAttachment$Params } from '../fn/form-attachments/form-attachments-download-attachment';
import { formAttachmentsDownloadAttachments } from '../fn/form-attachments/form-attachments-download-attachments';
import { FormAttachmentsDownloadAttachments$Params } from '../fn/form-attachments/form-attachments-download-attachments';
import { formAttachmentsGetFormAttachment } from '../fn/form-attachments/form-attachments-get-form-attachment';
import { FormAttachmentsGetFormAttachment$Params } from '../fn/form-attachments/form-attachments-get-form-attachment';
import { formAttachmentsGetFormAttachmentFilters } from '../fn/form-attachments/form-attachments-get-form-attachment-filters';
import { FormAttachmentsGetFormAttachmentFilters$Params } from '../fn/form-attachments/form-attachments-get-form-attachment-filters';
import { formAttachmentsUpdateFormAttachment } from '../fn/form-attachments/form-attachments-update-form-attachment';
import { FormAttachmentsUpdateFormAttachment$Params } from '../fn/form-attachments/form-attachments-update-form-attachment';
import { formAttachmentsUploadFormAttachment } from '../fn/form-attachments/form-attachments-upload-form-attachment';
import { FormAttachmentsUploadFormAttachment$Params } from '../fn/form-attachments/form-attachments-upload-form-attachment';
import { ResponseModelOfGetFormAttachmentFiltersResult } from '../models/response-model-of-get-form-attachment-filters-result';
import { ResponseModelOfGetFormAttachmentListResult } from '../models/response-model-of-get-form-attachment-list-result';
import { ResponseModelOfUpdateFormAttachmentResult } from '../models/response-model-of-update-form-attachment-result';
import { ResponseModelOfUploadFormAttachmentResult } from '../models/response-model-of-upload-form-attachment-result';

@Injectable({ providedIn: 'root' })
export class FormAttachmentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formAttachmentsGetFormAttachmentFilters()` */
  static readonly FormAttachmentsGetFormAttachmentFiltersPath = '/api/Forms/FormAttachments/filters';

  /**
   * Get form attachment filters according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formAttachmentsGetFormAttachmentFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsGetFormAttachmentFilters$Response(params: FormAttachmentsGetFormAttachmentFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormAttachmentFiltersResult>> {
    return formAttachmentsGetFormAttachmentFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * Get form attachment filters according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formAttachmentsGetFormAttachmentFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsGetFormAttachmentFilters(params: FormAttachmentsGetFormAttachmentFilters$Params, context?: HttpContext): Observable<ResponseModelOfGetFormAttachmentFiltersResult> {
    return this.formAttachmentsGetFormAttachmentFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetFormAttachmentFiltersResult>): ResponseModelOfGetFormAttachmentFiltersResult => r.body)
    );
  }

  /** Path part for operation `formAttachmentsGetFormAttachment()` */
  static readonly FormAttachmentsGetFormAttachmentPath = '/api/Forms/FormAttachments';

  /**
   * Returns form attachment according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formAttachmentsGetFormAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsGetFormAttachment$Response(params?: FormAttachmentsGetFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormAttachmentListResult>> {
    return formAttachmentsGetFormAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns form attachment according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formAttachmentsGetFormAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsGetFormAttachment(params?: FormAttachmentsGetFormAttachment$Params, context?: HttpContext): Observable<ResponseModelOfGetFormAttachmentListResult> {
    return this.formAttachmentsGetFormAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetFormAttachmentListResult>): ResponseModelOfGetFormAttachmentListResult => r.body)
    );
  }

  /** Path part for operation `formAttachmentsUpdateFormAttachment()` */
  static readonly FormAttachmentsUpdateFormAttachmentPath = '/api/Forms/FormAttachments';

  /**
   * Update form attachment according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formAttachmentsUpdateFormAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  formAttachmentsUpdateFormAttachment$Response(params?: FormAttachmentsUpdateFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUpdateFormAttachmentResult>> {
    return formAttachmentsUpdateFormAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Update form attachment according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formAttachmentsUpdateFormAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  formAttachmentsUpdateFormAttachment(params?: FormAttachmentsUpdateFormAttachment$Params, context?: HttpContext): Observable<ResponseModelOfUpdateFormAttachmentResult> {
    return this.formAttachmentsUpdateFormAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfUpdateFormAttachmentResult>): ResponseModelOfUpdateFormAttachmentResult => r.body)
    );
  }

  /** Path part for operation `formAttachmentsUploadFormAttachment()` */
  static readonly FormAttachmentsUploadFormAttachmentPath = '/api/Forms/FormAttachments';

  /**
   * Upload form attachment according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formAttachmentsUploadFormAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  formAttachmentsUploadFormAttachment$Response(params?: FormAttachmentsUploadFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>> {
    return formAttachmentsUploadFormAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload form attachment according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formAttachmentsUploadFormAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  formAttachmentsUploadFormAttachment(params?: FormAttachmentsUploadFormAttachment$Params, context?: HttpContext): Observable<ResponseModelOfUploadFormAttachmentResult> {
    return this.formAttachmentsUploadFormAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>): ResponseModelOfUploadFormAttachmentResult => r.body)
    );
  }

  /** Path part for operation `formAttachmentsDeleteFormAttachment()` */
  static readonly FormAttachmentsDeleteFormAttachmentPath = '/api/Forms/FormAttachments';

  /**
   * Delete form attachment according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formAttachmentsDeleteFormAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsDeleteFormAttachment$Response(params: FormAttachmentsDeleteFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>> {
    return formAttachmentsDeleteFormAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete form attachment according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formAttachmentsDeleteFormAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsDeleteFormAttachment(params: FormAttachmentsDeleteFormAttachment$Params, context?: HttpContext): Observable<ResponseModelOfUploadFormAttachmentResult> {
    return this.formAttachmentsDeleteFormAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>): ResponseModelOfUploadFormAttachmentResult => r.body)
    );
  }

  /** Path part for operation `formAttachmentsDownloadAttachment()` */
  static readonly FormAttachmentsDownloadAttachmentPath = '/api/Forms/FormAttachments/download/{formAttachmentId}';

  /**
   * Download file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formAttachmentsDownloadAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsDownloadAttachment$Response(params: FormAttachmentsDownloadAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return formAttachmentsDownloadAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Download file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formAttachmentsDownloadAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsDownloadAttachment(params: FormAttachmentsDownloadAttachment$Params, context?: HttpContext): Observable<Blob> {
    return this.formAttachmentsDownloadAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `formAttachmentsDownloadAttachments()` */
  static readonly FormAttachmentsDownloadAttachmentsPath = '/api/Forms/FormAttachments/download';

  /**
   * Download zipped attachments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formAttachmentsDownloadAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsDownloadAttachments$Response(params?: FormAttachmentsDownloadAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return formAttachmentsDownloadAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * Download zipped attachments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formAttachmentsDownloadAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formAttachmentsDownloadAttachments(params?: FormAttachmentsDownloadAttachments$Params, context?: HttpContext): Observable<Blob> {
    return this.formAttachmentsDownloadAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
