import { Pipe, PipeTransform } from '@angular/core';
import { getFileIcon } from '../funcitons/utils';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  transform(fileName: string): unknown {
    return getFileIcon(fileName);
  }

}
