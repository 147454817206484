/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ResponseModelOfSubmitRiskAssesmentResult } from '../models/response-model-of-submit-risk-assesment-result';
import { submitRiskAssesmentSubmitRiskAssesment } from '../fn/submit-risk-assesment/submit-risk-assesment-submit-risk-assesment';
import { SubmitRiskAssesmentSubmitRiskAssesment$Params } from '../fn/submit-risk-assesment/submit-risk-assesment-submit-risk-assesment';

@Injectable({ providedIn: 'root' })
export class SubmitRiskAssesmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `submitRiskAssesmentSubmitRiskAssesment()` */
  static readonly SubmitRiskAssesmentSubmitRiskAssesmentPath = '/api/RiskAssesment/SubmitRiskAssesment';

  /**
   * Evaluate a risk assesment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitRiskAssesmentSubmitRiskAssesment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitRiskAssesmentSubmitRiskAssesment$Response(params: SubmitRiskAssesmentSubmitRiskAssesment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfSubmitRiskAssesmentResult>> {
    return submitRiskAssesmentSubmitRiskAssesment(this.http, this.rootUrl, params, context);
  }

  /**
   * Evaluate a risk assesment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitRiskAssesmentSubmitRiskAssesment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitRiskAssesmentSubmitRiskAssesment(params: SubmitRiskAssesmentSubmitRiskAssesment$Params, context?: HttpContext): Observable<ResponseModelOfSubmitRiskAssesmentResult> {
    return this.submitRiskAssesmentSubmitRiskAssesment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfSubmitRiskAssesmentResult>): ResponseModelOfSubmitRiskAssesmentResult => r.body)
    );
  }

}
