/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RequestSupportFiltersResponse } from '../../models/request-support-filters-response';
import { TicketType } from '../../models/ticket-type';

export interface SupportGetAllSupportRequestsFilters$Params {
  tenantId?: string;
  ticketTypes?: TicketType | null;
}

export function supportGetAllSupportRequestsFilters(http: HttpClient, rootUrl: string, params?: SupportGetAllSupportRequestsFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<RequestSupportFiltersResponse>> {
  const rb = new RequestBuilder(rootUrl, supportGetAllSupportRequestsFilters.PATH, 'get');
  if (params) {
    rb.query('tenantId', params.tenantId, {});
    rb.query('ticketTypes', params.ticketTypes, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RequestSupportFiltersResponse>;
    })
  );
}

supportGetAllSupportRequestsFilters.PATH = '/api/Support/tickets/getallsupporttickets/filters';
