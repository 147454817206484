/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfUploadFormAttachmentResult } from '../../models/response-model-of-upload-form-attachment-result';

export interface FormAttachmentsUploadFormAttachment$Params {
      body?: {
'FormVersionId'?: string | null;
'ProjectId'?: string | null;
'FieldCode'?: string | null;
'Link'?: string | null;
'AttachmentType'?: string | null;
'Row'?: number;
'File'?: Blob | null;
'RowId'?: string | null;
'Name'?: string;
'Description'?: string;
}
}

export function formAttachmentsUploadFormAttachment(http: HttpClient, rootUrl: string, params?: FormAttachmentsUploadFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>> {
  const rb = new RequestBuilder(rootUrl, formAttachmentsUploadFormAttachment.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>;
    })
  );
}

formAttachmentsUploadFormAttachment.PATH = '/api/Forms/FormAttachments';
