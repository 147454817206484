/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AllTaskSortByType } from '../../models/all-task-sort-by-type';
import { ModuleType } from '../../models/module-type';
import { OrderTypeModel } from '../../models/order-type-model';
import { ResponseModelOfGetAllTaskListResult } from '../../models/response-model-of-get-all-task-list-result';
import { SectionStatus } from '../../models/section-status';

export interface AllTasksGetAllTask$Params {

/**
 * Gets or sets a tenant ID to be requested.
 */
  tenantId: string;

/**
 * Gets or sets a requested module.
 */
  module?: Array<string> | null;

/**
 * Gets or sets a sections to be filtered.
 */
  section?: Array<string> | null;

/**
 * Gets or sets a project IDs to be filtered.
 */
  projectId?: Array<string> | null;

/**
 * Gets or sets a modules to be fitlered.
 */
  moduleType?: Array<ModuleType> | null;

/**
 * Gets or sets a requested status.
 */
  status?: Array<SectionStatus> | null;

/**
 * Gets or sets all task sort type.
 */
  allTaskSortByType?: AllTaskSortByType;

/**
 * Gets or sets all task order type.
 */
  orderType?: OrderTypeModel;

/**
 * Gets or sets a requested recordFrom.
 */
  recordFrom?: number | null;

/**
 * Gets or sets requested recordFrom.
 */
  recordTo?: number | null;
}

export function allTasksGetAllTask(http: HttpClient, rootUrl: string, params: AllTasksGetAllTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetAllTaskListResult>> {
  const rb = new RequestBuilder(rootUrl, allTasksGetAllTask.PATH, 'get');
  if (params) {
    rb.query('tenantId', params.tenantId, {});
    rb.query('module', params.module, {"style":"form","explode":true});
    rb.query('section', params.section, {"style":"form","explode":true});
    rb.query('projectId', params.projectId, {"style":"form","explode":true});
    rb.query('moduleType', params.moduleType, {"style":"form","explode":true});
    rb.query('status', params.status, {"style":"form","explode":true});
    rb.query('allTaskSortByType', params.allTaskSortByType, {});
    rb.query('orderType', params.orderType, {});
    rb.query('recordFrom', params.recordFrom, {});
    rb.query('recordTo', params.recordTo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetAllTaskListResult>;
    })
  );
}

allTasksGetAllTask.PATH = '/api/Forms/AllTasks';
