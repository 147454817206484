/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfGetModuleResult } from '../../models/response-model-of-get-module-result';

export interface ModuleGetModule$Params {

/**
 * Gets records until this position.
 */
  moduleId: string;
}

export function moduleGetModule(http: HttpClient, rootUrl: string, params: ModuleGetModule$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetModuleResult>> {
  const rb = new RequestBuilder(rootUrl, moduleGetModule.PATH, 'get');
  if (params) {
    rb.query('moduleId', params.moduleId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetModuleResult>;
    })
  );
}

moduleGetModule.PATH = '/api/Forms/Module';
