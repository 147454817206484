/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateProjectApiParameters } from '../../models/create-project-api-parameters';
import { ResponseModelOfCreateProjectResult } from '../../models/response-model-of-create-project-result';

export interface ProjectCreateProject$Params {
  
    /**
     * Request Model for creating project.
     */
    body: CreateProjectApiParameters
}

export function projectCreateProject(http: HttpClient, rootUrl: string, params: ProjectCreateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCreateProjectResult>> {
  const rb = new RequestBuilder(rootUrl, projectCreateProject.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfCreateProjectResult>;
    })
  );
}

projectCreateProject.PATH = '/api/Projects/Project';
