/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfUploadFormAttachmentResult } from '../../models/response-model-of-upload-form-attachment-result';

export interface FormAttachmentsDeleteFormAttachment$Params {

/**
 * ID of the requested form attachment.
 */
  formAttachmentId: string;

/**
 * Form attachment row.
 */
  row: number;

/**
 * Form attachment rowId.
 */
  rowId?: string | null;
}

export function formAttachmentsDeleteFormAttachment(http: HttpClient, rootUrl: string, params: FormAttachmentsDeleteFormAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>> {
  const rb = new RequestBuilder(rootUrl, formAttachmentsDeleteFormAttachment.PATH, 'delete');
  if (params) {
    rb.query('formAttachmentId', params.formAttachmentId, {});
    rb.query('row', params.row, {});
    rb.query('rowId', params.rowId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfUploadFormAttachmentResult>;
    })
  );
}

formAttachmentsDeleteFormAttachment.PATH = '/api/Forms/FormAttachments';
