/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RejectProjectRequestApiParameters } from '../../models/reject-project-request-api-parameters';
import { ResponseModelOfRejectProjectRequestResult } from '../../models/response-model-of-reject-project-request-result';

export interface RejectProjectRequestRejectProjectRequest$Params {
  
    /**
     * Request model for approving project request.
     */
    body: RejectProjectRequestApiParameters
}

export function rejectProjectRequestRejectProjectRequest(http: HttpClient, rootUrl: string, params: RejectProjectRequestRejectProjectRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfRejectProjectRequestResult>> {
  const rb = new RequestBuilder(rootUrl, rejectProjectRequestRejectProjectRequest.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfRejectProjectRequestResult>;
    })
  );
}

rejectProjectRequestRejectProjectRequest.PATH = '/api/Projects/ProjectRequest/Reject';
