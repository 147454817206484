/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { moduleDefinitionImportModuleDefinition } from '../fn/module-definition/module-definition-import-module-definition';
import { ModuleDefinitionImportModuleDefinition$Params } from '../fn/module-definition/module-definition-import-module-definition';

@Injectable({ providedIn: 'root' })
export class ModuleDefinitionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `moduleDefinitionImportModuleDefinition()` */
  static readonly ModuleDefinitionImportModuleDefinitionPath = '/api/Forms/ModuleDefinition';

  /**
   * Imports module definition and returns result of the operation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moduleDefinitionImportModuleDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moduleDefinitionImportModuleDefinition$Response(params: ModuleDefinitionImportModuleDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return moduleDefinitionImportModuleDefinition(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports module definition and returns result of the operation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moduleDefinitionImportModuleDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moduleDefinitionImportModuleDefinition(params: ModuleDefinitionImportModuleDefinition$Params, context?: HttpContext): Observable<void> {
    return this.moduleDefinitionImportModuleDefinition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
