/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AllProjectRiskType } from '../../models/all-project-risk-type';
import { OrderTypeModel } from '../../models/order-type-model';
import { ProjectSortByType } from '../../models/project-sort-by-type';
import { ProjectStatusType } from '../../models/project-status-type';
import { RequestedProjectType } from '../../models/requested-project-type';
import { ResponseModelOfGetProjectListResult } from '../../models/response-model-of-get-project-list-result';

export interface ProjectGetProjects$Params {

/**
 * Gets or sets a requested tenant.
 */
  tenantId: string;

/**
 * Gets or sets a value indicating whether only projects where current is participationg are returned.
 */
  currentUserOnly?: boolean;

/**
 * Gets or sets a requested project.
 */
  projectId?: string | null;

/**
 * Gets or sets a requested project type.
 */
  requestedProjectType: RequestedProjectType;

/**
 * Gets or sets project sort type.
 */
  sortByType?: ProjectSortByType;

/**
 * Gets or sets project sort type.
 */
  orderType?: OrderTypeModel;

/**
 * Gets or sets project status.
 */
  projectStatus?: Array<ProjectStatusType> | null;

/**
 * Gets or sets list of project's name requested.
 */
  projectName?: Array<string> | null;

/**
 * Gets or sets list of current user role requested.
 */
  myRole?: Array<string> | null;

/**
 * Gets or sets list of project's manager requested.
 */
  projectManager?: Array<string> | null;

/**
 * Gets or sets list of requested project approver.
 */
  projectApprover?: Array<string> | null;

/**
 * Gets or sets list of requested project requestors.
 */
  projectRequestor?: Array<string> | null;

/**
 * Gets or sets all project's risk types.
 */
  allRiskType?: Array<AllProjectRiskType> | null;

/**
 * Gets or sets a requested recordFrom.
 */
  recordFrom?: number | null;

/**
 * Gets or sets requested recordFrom.
 */
  recordTo?: number | null;
}

export function projectGetProjects(http: HttpClient, rootUrl: string, params: ProjectGetProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetProjectListResult>> {
  const rb = new RequestBuilder(rootUrl, projectGetProjects.PATH, 'get');
  if (params) {
    rb.query('tenantId', params.tenantId, {});
    rb.query('currentUserOnly', params.currentUserOnly, {});
    rb.query('projectId', params.projectId, {});
    rb.query('requestedProjectType', params.requestedProjectType, {});
    rb.query('sortByType', params.sortByType, {});
    rb.query('orderType', params.orderType, {});
    rb.query('projectStatus', params.projectStatus, {"style":"form","explode":true});
    rb.query('projectName', params.projectName, {"style":"form","explode":true});
    rb.query('myRole', params.myRole, {"style":"form","explode":true});
    rb.query('projectManager', params.projectManager, {"style":"form","explode":true});
    rb.query('projectApprover', params.projectApprover, {"style":"form","explode":true});
    rb.query('projectRequestor', params.projectRequestor, {"style":"form","explode":true});
    rb.query('allRiskType', params.allRiskType, {"style":"form","explode":true});
    rb.query('recordFrom', params.recordFrom, {});
    rb.query('recordTo', params.recordTo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetProjectListResult>;
    })
  );
}

projectGetProjects.PATH = '/api/Projects/Project';
