/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseModelOfGetAllTaskListFiltersResult } from '../../models/response-model-of-get-all-task-list-filters-result';
import { SectionStatus } from '../../models/section-status';

export interface AllTasksFilters$Params {

/**
 * Gets or sets a tenant ID to be requested.
 */
  tenantId: string;

/**
 * Gets or sets a requested status.
 */
  status?: Array<SectionStatus> | null;
}

export function allTasksFilters(http: HttpClient, rootUrl: string, params: AllTasksFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetAllTaskListFiltersResult>> {
  const rb = new RequestBuilder(rootUrl, allTasksFilters.PATH, 'get');
  if (params) {
    rb.query('tenantId', params.tenantId, {});
    rb.query('status', params.status, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetAllTaskListFiltersResult>;
    })
  );
}

allTasksFilters.PATH = '/api/Forms/AllTasks/filters';
