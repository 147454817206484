/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ResponseModelOfCreateUserResult } from '../models/response-model-of-create-user-result';
import { ResponseModelOfDeleteUserResult } from '../models/response-model-of-delete-user-result';
import { ResponseModelOfGetUserInfoResult } from '../models/response-model-of-get-user-info-result';
import { ResponseModelOfGetUserListFiltersResult } from '../models/response-model-of-get-user-list-filters-result';
import { ResponseModelOfGetUserListResult } from '../models/response-model-of-get-user-list-result';
import { ResponseModelOfUpdateUserResult } from '../models/response-model-of-update-user-result';
import { userCreateUser } from '../fn/user/user-create-user';
import { UserCreateUser$Params } from '../fn/user/user-create-user';
import { userDeleteUser } from '../fn/user/user-delete-user';
import { UserDeleteUser$Params } from '../fn/user/user-delete-user';
import { userGetUserFilters } from '../fn/user/user-get-user-filters';
import { UserGetUserFilters$Params } from '../fn/user/user-get-user-filters';
import { userGetUserInfo } from '../fn/user/user-get-user-info';
import { UserGetUserInfo$Params } from '../fn/user/user-get-user-info';
import { userGetUsers } from '../fn/user/user-get-users';
import { UserGetUsers$Params } from '../fn/user/user-get-users';
import { userUpdateUser } from '../fn/user/user-update-user';
import { UserUpdateUser$Params } from '../fn/user/user-update-user';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userGetUsers()` */
  static readonly UserGetUsersPath = '/api/Users/Users';

  /**
   * Returns user/users requests according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUsers$Response(params?: UserGetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetUserListResult>> {
    return userGetUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns user/users requests according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUsers(params?: UserGetUsers$Params, context?: HttpContext): Observable<ResponseModelOfGetUserListResult> {
    return this.userGetUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetUserListResult>): ResponseModelOfGetUserListResult => r.body)
    );
  }

  /** Path part for operation `userUpdateUser()` */
  static readonly UserUpdateUserPath = '/api/Users/Users';

  /**
   * Updates user in the database.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUpdateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdateUser$Response(params: UserUpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfUpdateUserResult>> {
    return userUpdateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates user in the database.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUpdateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdateUser(params: UserUpdateUser$Params, context?: HttpContext): Observable<ResponseModelOfUpdateUserResult> {
    return this.userUpdateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfUpdateUserResult>): ResponseModelOfUpdateUserResult => r.body)
    );
  }

  /** Path part for operation `userCreateUser()` */
  static readonly UserCreateUserPath = '/api/Users/Users';

  /**
   * Creates new user in the databse.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCreateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreateUser$Response(params: UserCreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCreateUserResult>> {
    return userCreateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new user in the databse.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCreateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreateUser(params: UserCreateUser$Params, context?: HttpContext): Observable<ResponseModelOfCreateUserResult> {
    return this.userCreateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfCreateUserResult>): ResponseModelOfCreateUserResult => r.body)
    );
  }

  /** Path part for operation `userDeleteUser()` */
  static readonly UserDeleteUserPath = '/api/Users/Users';

  /**
   * Returns id of deleted user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDeleteUser$Response(params: UserDeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfDeleteUserResult>> {
    return userDeleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns id of deleted user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDeleteUser(params: UserDeleteUser$Params, context?: HttpContext): Observable<ResponseModelOfDeleteUserResult> {
    return this.userDeleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfDeleteUserResult>): ResponseModelOfDeleteUserResult => r.body)
    );
  }

  /** Path part for operation `userGetUserInfo()` */
  static readonly UserGetUserInfoPath = '/api/Users/Users/userinfo';

  /**
   * User logged in info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserInfo$Response(params?: UserGetUserInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetUserInfoResult>> {
    return userGetUserInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * User logged in info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserInfo(params?: UserGetUserInfo$Params, context?: HttpContext): Observable<ResponseModelOfGetUserInfoResult> {
    return this.userGetUserInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetUserInfoResult>): ResponseModelOfGetUserInfoResult => r.body)
    );
  }

  /** Path part for operation `userGetUserFilters()` */
  static readonly UserGetUserFiltersPath = '/api/Users/Users/filters';

  /**
   * Returns user filters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserFilters$Response(params?: UserGetUserFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetUserListFiltersResult>> {
    return userGetUserFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns user filters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserFilters(params?: UserGetUserFilters$Params, context?: HttpContext): Observable<ResponseModelOfGetUserListFiltersResult> {
    return this.userGetUserFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetUserListFiltersResult>): ResponseModelOfGetUserListFiltersResult => r.body)
    );
  }

}
