import { NgModule } from '@angular/core';
import {
  AuthModule,
  StsConfigHttpLoader,
  StsConfigLoader,
} from 'angular-auth-oidc-client';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

// export const httpLoaderFactory = (
//   apiConfigurationService: ApiConfigurationService
// ) => {

//   const config$ =  apiConfigurationService.currentConfiguration$.pipe(
//     map((data) => {
//       return {
//         authority: data.authIssuerUrl,
//         redirectUrl: data.authRedirectUrl,
//         postLogoutRedirectUri: window.location.origin,
//         clientId: data.authClientId,
//         scope: 'openid cloudEmail', // 'openid profile offline_access ' + your scopes
//         responseType: 'code',
//         silentRenew: true,
//         useRefreshToken: true,
//         renewTimeBeforeTokenExpiresInSeconds: 30,
//         //ToDo : Implement secured paths and proxy
//       };
//     })
//   );
//   return new StsConfigHttpLoader(config$);
// }

export const httpLoaderFactory = () => {
  const config$ = of({
    authority: environment.oauth2Issuer,
    redirectUrl: environment.redirectUri,
    postLogoutRedirectUri: window.location.origin,
    clientId: environment.clientId,
    scope: 'openid cloudEmail', // 'openid profile offline_access ' + your scopes
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    //ToDo : Implement secured paths and proxy
  });
  return new StsConfigHttpLoader(config$);
};
@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
