/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RequestCommentSupport } from '../../models/request-comment-support';
import { RequestDocumentSupport } from '../../models/request-document-support';
import { RequestEventLog } from '../../models/request-event-log';
import { ResponseResult } from '../../models/response-result';

export interface SupportSaveSupportTicket$Params {
      body?: {
'Id'?: string;
'TenantId'?: string;
'Description'?: string | null;
'Title'?: string | null;
'Priority'?: number;
'PriorityName'?: string | null;
'Status'?: number;
'StatusName'?: string | null;
'OwnerId'?: string;
'LastUpdate'?: string | null;
'CreatedDate'?: string | null;
'OwnerName'?: string | null;
'AssigneName'?: string | null;
'AssigneeId'?: string | null;
'GroupAssigneeId'?: string | null;
'isDelete'?: boolean;
'TicketNumber'?: number | null;
'Documents'?: Array<RequestDocumentSupport> | null;
'RequestComments'?: Array<RequestCommentSupport> | null;
'EventLog'?: Array<RequestEventLog> | null;
'Files'?: Array<Blob> | null;
}
}

export function supportSaveSupportTicket(http: HttpClient, rootUrl: string, params?: SupportSaveSupportTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
  const rb = new RequestBuilder(rootUrl, supportSaveSupportTicket.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseResult>;
    })
  );
}

supportSaveSupportTicket.PATH = '/api/Support/tickets/saveticket';
