import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials'
})
export class UserInitialsPipe implements PipeTransform {

  transform(fullName: string): unknown {
    if (!fullName) {
      return ""
    }
    return this.getInitials(fullName);
  }

  getInitials(fullName) {
    const nameParts = fullName.split(' ');
    const initials = nameParts.map(part => part.charAt(0).toUpperCase());
    return initials.join('');
  }

}
