/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { formDataValidationCalculateFormFieldsVisibility } from '../fn/form-data-validation/form-data-validation-calculate-form-fields-visibility';
import { FormDataValidationCalculateFormFieldsVisibility$Params } from '../fn/form-data-validation/form-data-validation-calculate-form-fields-visibility';
import { ResponseModelOfCalculateFormFieldsVisibilityResult } from '../models/response-model-of-calculate-form-fields-visibility-result';

@Injectable({ providedIn: 'root' })
export class FormDataValidationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formDataValidationCalculateFormFieldsVisibility()` */
  static readonly FormDataValidationCalculateFormFieldsVisibilityPath = '/api/Forms/FormDataValidation/CalculateFormFieldsVisibility';

  /**
   * Checks and provides visibility data of the form identified by input parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formDataValidationCalculateFormFieldsVisibility()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formDataValidationCalculateFormFieldsVisibility$Response(params: FormDataValidationCalculateFormFieldsVisibility$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCalculateFormFieldsVisibilityResult>> {
    return formDataValidationCalculateFormFieldsVisibility(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks and provides visibility data of the form identified by input parameters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formDataValidationCalculateFormFieldsVisibility$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formDataValidationCalculateFormFieldsVisibility(params: FormDataValidationCalculateFormFieldsVisibility$Params, context?: HttpContext): Observable<ResponseModelOfCalculateFormFieldsVisibilityResult> {
    return this.formDataValidationCalculateFormFieldsVisibility$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfCalculateFormFieldsVisibilityResult>): ResponseModelOfCalculateFormFieldsVisibilityResult => r.body)
    );
  }

}
