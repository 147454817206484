/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ImportRiskApiParameters } from '../../models/import-risk-api-parameters';

export interface RiskTypesImportRiskTypes$Params {
  
    /**
     * Risk types definitions.
     */
    body: ImportRiskApiParameters
}

export function riskTypesImportRiskTypes(http: HttpClient, rootUrl: string, params: RiskTypesImportRiskTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, riskTypesImportRiskTypes.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

riskTypesImportRiskTypes.PATH = '/api/RiskAssesment/RiskTypes';
