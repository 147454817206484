import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'page-items-selector',
  templateUrl: './page-items-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageItemsSelectorComponent implements OnInit{

  ngOnInit() {
    if(this.pageInitialization) {
      this.change(this.pageInitialization)
    } 
  }

  @Input () pageInitialization: number
  _pageItems: number = 10;
  @Input() set pageItems(v: number) {
    this._pageItems = v;
  };
  @Input() pageItemsOptions: number[] = [5,10,50,100];
  @Output() pageItemsChange = new EventEmitter<number>();

  change(x: number) {
    this.pageItemsChange.emit(x);
    this._pageItems = x;
  }

}
