/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { allTasksFilters } from '../fn/all-tasks/all-tasks-filters';
import { AllTasksFilters$Params } from '../fn/all-tasks/all-tasks-filters';
import { allTasksGetAllTask } from '../fn/all-tasks/all-tasks-get-all-task';
import { AllTasksGetAllTask$Params } from '../fn/all-tasks/all-tasks-get-all-task';
import { ResponseModelOfGetAllTaskListFiltersResult } from '../models/response-model-of-get-all-task-list-filters-result';
import { ResponseModelOfGetAllTaskListResult } from '../models/response-model-of-get-all-task-list-result';

@Injectable({ providedIn: 'root' })
export class AllTasksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `allTasksGetAllTask()` */
  static readonly AllTasksGetAllTaskPath = '/api/Forms/AllTasks';

  /**
   * All tasks of currently logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allTasksGetAllTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  allTasksGetAllTask$Response(params: AllTasksGetAllTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetAllTaskListResult>> {
    return allTasksGetAllTask(this.http, this.rootUrl, params, context);
  }

  /**
   * All tasks of currently logged in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allTasksGetAllTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allTasksGetAllTask(params: AllTasksGetAllTask$Params, context?: HttpContext): Observable<ResponseModelOfGetAllTaskListResult> {
    return this.allTasksGetAllTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetAllTaskListResult>): ResponseModelOfGetAllTaskListResult => r.body)
    );
  }

  /** Path part for operation `allTasksFilters()` */
  static readonly AllTasksFiltersPath = '/api/Forms/AllTasks/filters';

  /**
   * Filters for filtering all tasks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allTasksFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  allTasksFilters$Response(params: AllTasksFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetAllTaskListFiltersResult>> {
    return allTasksFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * Filters for filtering all tasks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allTasksFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allTasksFilters(params: AllTasksFilters$Params, context?: HttpContext): Observable<ResponseModelOfGetAllTaskListFiltersResult> {
    return this.allTasksFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetAllTaskListFiltersResult>): ResponseModelOfGetAllTaskListFiltersResult => r.body)
    );
  }

}
