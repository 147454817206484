import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const termsAndConditionsGuard: CanActivateFn = (route, state) => {
  const termsAndConditionsLS = JSON.parse(localStorage.getItem('termsAndConditionsDisplay'));
  const router = inject(Router);
  let letUserIn: boolean = false;
  if (termsAndConditionsLS === null) {
    letUserIn = false;
  } else {
    letUserIn = !termsAndConditionsLS;
  }
  if (!letUserIn) {
    router.navigate(['/']);
  }
  return letUserIn;
};