import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PWCLogoType, ThemeService } from '@pwc/appkit-styled-lib';

@Component({
  selector: 'app-decline-log-out',
  templateUrl: './decline-log-out.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeclineLogOutComponent {
  constructor(
    private router: Router,
    private themeService: ThemeService
  ) {}

  theme$ = this.themeService.theme$;
  
  logIn() {
    this.router.navigate(['/']);
  }

  lightType: PWCLogoType = 'black-borders';
  darkType: PWCLogoType = 'white-borders';
}
