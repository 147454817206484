<div class="h-[2px] w-full relative overflow-hidden">
  <div
    *ngIf="loading$ | async"
    class="absolute -translate-x-full h-full bg-primary w-2/3"
    style="animation: loadingBar 1.6s ease-in-out infinite"
  ></div>
</div>
<nav class="px-6 bg-white h-16 flex items-center dark:bg-neutral-850 w-full">
  <div
  (click)="goToHome()"
    class="flex gap-2 items-center cursor-pointer"
  >
    <pwc-logo-text></pwc-logo-text>
    AI Compliance Tool
    <div *ngIf="badgeTest" class="rounded-2xl h-8 flex items-center py-2 px-4 bg-[#8E34F4] text-white">{{badgeTest}}</div>
  </div>
  <ng-container *ngIf="{ isAuth: isAuth$ | async } as auth">
    <ng-container *ngIf="auth.isAuth">
      <div class="flex items-center gap-4 ms-auto">
        <ng-container *ngIf="tenants$ | async as tenants">
          <ng-container *ngIf="currentTenantUser$ | async as currentTenantUser">
            <ngx-button
              variation="transparent"
              [routerLink]="['/administration']"
              routerLinkActive="bg-primary-lighten-200 rounded-md"
            >
              <div class="flex gap-1 items-center">
                <span class="Appkit4-icon icon-setting-fill"></span>
                Admin
              </div>
            </ngx-button>
            <ngx-button
              variation="transparent"
              [routerLink]="['/support']"
              routerLinkActive="bg-primary-lighten-200 rounded-md"
            >
              <div class="flex gap-1 items-center">
                <span class="Appkit4-icon icon-ask-question-fill"></span>
                Support Hub
              </div>
            </ngx-button>
            <div *ngIf="tenants.length == 1" class="px-4 h-full">
              <div class="flex items-center gap-2">
                {{ tenants[0].name }}
              </div>
            </div>
            <div *ngIf="tenants.length > 1">
              <ngx-button variation="transparent" [menu]="tenantMenu">
                {{ currentTenantUser.tenant.name }}
                <span
                  class="Appkit4-icon icon-down-chevron-outline ms-3"
                ></span>
              </ngx-button>
            </div>
            <ng-template #tenantMenu>
              <ul
                class="p-3 mt-2 bg-white dark:bg-neutral-800 flex flex-col shadow-md z-10 relative"
              >
                <li *ngFor="let tenant of tenants">
                  <ngx-button
                    variation="transparent"
                    (click)="changeTenant(tenant)"
                  >
                    {{ tenant.name }}
                  </ngx-button>
                </li>
              </ul>
            </ng-template>
            <div
              [menu]="menu"
              [menuClickClose]="false"
              class="w-8 h-8 rounded-full flex items-center cursor-pointer justify-center bg-primary dark:bg-primary-lighten-100 tracking-wide m-2"
            >
              {{ currentTenantUser.name | userInitials }}
            </div>
            <ng-template #menu>
              <div
                class="p-3 mt-2 bg-white dark:bg-neutral-800 flex flex-col gap-1 shadow-md"
              >
                <div class="flex items-center gap-2">
                  <ng-container
                    *ngIf="currentTenantUser.isAdmin || simulatedUser"
                  >
                    <ngx-select
                      label="User to change"
                      (valueChange)="changeUser($event)"
                      class="block w-full"
                      [value]="simulatedUser"
                    >
                      <ngx-button
                        *ngIf="simulatedUser"
                        variation="negative"
                        (click)="clearSimulatedUser()"
                        class="w-full"
                        >Clear user and reload</ngx-button
                      >
                      <ngx-option
                        *ngFor="let user of projectUsers$ | async"
                        [value]="user"
                        [displayValue]="user.name"
                        >{{ user.name }}</ngx-option
                      >
                    </ngx-select>
                  </ng-container>
                </div>
                <ngx-button
                  variation="transparent"
                  *ngIf="lightMode$ | async as lightMode"
                  (click)="changeMode(lightMode)"
                  class="w-full"
                  cssClass="w-full !justify-start"
                >
                  <div class="flex gap-1 items-center">
                    <ng-container *ngIf="lightMode === 'light'; else dark">
                      <span class="Appkit4-icon icon-moon-outline"></span>
                    </ng-container>
                    <ng-template #dark>
                      <span class="Appkit4-icon icon-brightness-outline"></span>
                    </ng-template>
                    Dark/light mode
                  </div>
                </ngx-button>
                <ngx-button
                  variation="transparent"
                  *ngIf="activeTheme$ | async as activeTheme"
                  (click)="changeTheme(activeTheme)"
                  class="w-full"
                  cssClass="w-full !justify-start"
                >
                  <div class="flex gap-1 items-center">
                    <brand-palette-icon></brand-palette-icon>
                    Change color
                  </div>
                </ngx-button>
                <ngx-button
                  variation="transparent"
                  menu-close
                  (click)="logout()"
                  class="w-full"
                  cssClass="w-full !justify-start"
                >
                  <div class="flex gap-1 items-center">
                    <span class="Appkit4-icon icon-log-out-outline"></span>
                    Log out
                  </div>
                </ngx-button>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
      <div class="h-[2px]"></div>
    </ng-container>
  </ng-container>
</nav>
<div class="w-full flex-1 flex flex-col">
  <router-outlet class="main-router"></router-outlet>
  <footer class="text-sm leading-5 p-2 block mt-auto left-0 pl-10 pt-6 pb-3">
    © {{year}} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.
    <a href="https://www.pwc.cz/aict" class="text-primary underline">About application</a>
  </footer>
</div>
<div class="absolute flex w-screen h-screen" *ngIf="termsAndConditionsDisplay">
  <div class="absolute top-0 bottom-0 left-0 right-0" style="backdrop-filter: blur(2px);"></div>
  <ngx-modal [open]="termsAndConditionsDisplay" [autoClose]="false">
    <div class="flex flex-col gap-5 rounded-lg p-4 m-auto bg-white dark:bg-neutral-800 z-10" style="width: 540px; height: 336px;">
      <h1 class="text-xl leading-6 text-left">Terms of use</h1>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aliquam erat volutpat. Nullam eget nisl. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in, elit. Pellentesque ipsum. Aliquam erat volutpat. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor. </p>
      <div>
        <form [formGroup]="checkBox">
          <ngx-checkbox formControlName="checkBoxValue" label="By checking the box, you acknowledge and accept the terms and conditions governing use of this application."></ngx-checkbox>
        </form>
      </div>
      <div class="flex justify-end gap-3">
        <ngx-button variation='secondary' (click)="Decline()">Decline</ngx-button>
        <ngx-button [disabled]="!(checkBox.get('checkBoxValue').value)" variation='primary' (click)="Accept()">Accept</ngx-button>
      </div>
    </div>
  </ngx-modal>
</div>


<!-- <ng-template #helpMenu>
  <div class="p-3 mt-2 bg-white dark:bg-neutral-800 flex flex-col shadow-md">
    <ngx-button
      variation="transparent"
    >
      <div class="flex gap-1 items-center">
        <span class="Appkit4-icon icon-ticket-outline"></span>
        Submit a ticket
      </div>
    </ngx-button>
    <ngx-button
      variation="transparent"
    >
      <div class="flex gap-1 items-center">
        <span class="Appkit4-icon icon-notebook-outline"></span>
        Guide
      </div>
    </ngx-button>
    <ngx-button
      variation="transparent"
    >
      <div class="flex gap-1 items-center">
        <span class="Appkit4-icon icon-information-outline"></span>
        About
      </div>
    </ngx-button>
  </div>
</ng-template> -->
