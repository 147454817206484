import { EnvironmentT } from '../models/general.model';

export function removeEmptyProperties(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      var value = obj[key];

      if (value === null || value === '' || value === undefined) {
        delete obj[key];
      }

      // Recursively check nested objects
      if (typeof value === 'object' && !Array.isArray(value)) {
        removeEmptyProperties(value);
      }
    }
  }

  return obj;
}

export function replaceCharactersAfterIndex(inputString, index, replacement) {
  if (index >= inputString.length) {
    return inputString; // No need to replace if index is out of range
  }
  return inputString.slice(0, index) + replacement;
}

export function differenceInDays(date1: Date, date2: Date): number {
  // Convert the input strings to Date objects
  const startDate: Date = date1;
  const endDate: Date = date2;

  // Calculate the difference in milliseconds between the two dates
  const differenceInMilliseconds: number =
    endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const millisecondsPerDay: number = 24 * 60 * 60 * 1000;
  const differenceInDays: number = Math.floor(
    differenceInMilliseconds / millisecondsPerDay
  );

  return differenceInDays;
}

export function addDaysToDate(date: Date, daysToAdd: number): Date {
  const resultDate = new Date(date); // Create a new Date object to avoid modifying the original date.

  // Calculate the new date by adding the specified number of days in milliseconds.
  const millisecondsToAdd = daysToAdd * 24 * 60 * 60 * 1000;
  resultDate.setTime(resultDate.getTime() + millisecondsToAdd);

  return resultDate;
}

export function countUniqueNumbers(arr: number[]): number {
  // Create a Set to store unique values
  const uniqueValues = new Set<number>(arr);

  // Return the size of the Set, which represents the count of unique values
  return uniqueValues.size;
}

export function formatDateToYYYYMMDD(date) {
  if (typeof date === 'string') {
    return date;
  }
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function areDatesEqual(date1: Date, date2: Date): boolean {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  return year1 === year2 && month1 === month2 && day1 === day2;
}

export function getEnvironment(): EnvironmentT {
  if (location.href.startsWith('https://localhost:4200')) {
    return EnvironmentT.LOCAL;
  }
  if (
    location.href.includes('dev.aicompliance') ||
    location.href.includes('AI_Captain-Dev-FE')
  ) {
    return EnvironmentT.DEV;
  }
  if (
    location.href.includes('test.aicompliance') ||
    location.href.includes('AI_Captain-Test-FE')
  ) {
    return EnvironmentT.TEST;
  }
  if (location.href.includes('stage.aicompliance')) {
    return EnvironmentT.STAGE;
  }
  return EnvironmentT.PROD;
}

export function getEnvironmentBadge(env: EnvironmentT): string | null {
  switch (env) {
    case EnvironmentT.DEV:
      return 'Development';
    case EnvironmentT.LOCAL:
      return 'Local';
    case EnvironmentT.STAGE:
      return 'Stage';
    case EnvironmentT.TEST:
      return 'Test';
    default:
      return null;
      break;
  }
}

export function getFileIcon(fileName: string): string {
  const ext = getFileExtension(fileName);
  switch (ext) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
      return 'icon-image-outline';

    case 'pdf':
      return 'icon-pdf-outline';

    case 'docx':
      return 'icon-doc-outline';

    case 'zip':
      return 'icon-file-outline';

    case 'pptx':
      return 'icon-ppt-outline';

    // Add more cases as needed
    case 'xls':
    case 'xlsx':
      return 'icon-xls-outline';
    default:
      return 'icon-document-file-outline';
  }
}

export function getFileExtension(fileName: string): string | null {
  const lastDotIndex = fileName.lastIndexOf('.');

  if (lastDotIndex === -1) {
    // No dot found, meaning no file extension
    return null;
  }

  // Extract the file extension (substring after the last dot)
  const extension = fileName.substring(lastDotIndex + 1);

  return extension.toLowerCase(); // Optional: convert to lowercase for consistency
}
