import { Pipe, PipeTransform } from '@angular/core';
import { RoleModel, UserProjectModel } from '../services/api/models';

@Pipe({
  name: 'userProjectToRole'
})
export class UserProjectToRolePipe implements PipeTransform {

  transform(projects: Array<UserProjectModel>, projectId: string): string {
    const role = projects.find(p => p.id.toLowerCase() === projectId.toLowerCase()).roles[0].name;
    return role;
  }

}
