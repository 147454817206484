/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrderTypeModel } from '../../models/order-type-model';
import { ResponseModelOfGetUserListResult } from '../../models/response-model-of-get-user-list-result';
import { UserSortByType } from '../../models/user-sort-by-type';

export interface UserGetUsers$Params {

/**
 * Gets or sets a requested user Id.
 */
  userId?: string | null;

/**
 * Gets or sets a requested project.
 */
  projectId?: string | null;

/**
 * Gets or sets a requested project.
 */
  excludeUsersFromProjectId?: string | null;

/**
 * Gets or sets a requested tenant.
 */
  tenantId?: string | null;

/**
 * Gets or sets project sort type.
 */
  userSortbyType?: UserSortByType;

/**
 * Gets or sets project sort type.
 */
  orderType?: OrderTypeModel;

/**
 * Gets or sets user name.
 */
  userName?: Array<string> | null;

/**
 * Gets or sets user email.
 */
  userEmail?: Array<string> | null;

/**
 * Gets or sets user roles.
 */
  userRole?: Array<string> | null;

/**
 * Gets or sets excluded user roles.
 */
  excludeRole?: Array<string> | null;

/**
 * Gets or sets a requested recordFrom.
 */
  recordFrom?: number | null;

/**
 * Gets or sets requested recordFrom.
 */
  recordTo?: number | null;
}

export function userGetUsers(http: HttpClient, rootUrl: string, params?: UserGetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetUserListResult>> {
  const rb = new RequestBuilder(rootUrl, userGetUsers.PATH, 'get');
  if (params) {
    rb.query('userId', params.userId, {});
    rb.query('projectId', params.projectId, {});
    rb.query('excludeUsersFromProjectId', params.excludeUsersFromProjectId, {});
    rb.query('tenantId', params.tenantId, {});
    rb.query('userSortbyType', params.userSortbyType, {});
    rb.query('orderType', params.orderType, {});
    rb.query('userName', params.userName, {"style":"form","explode":true});
    rb.query('userEmail', params.userEmail, {"style":"form","explode":true});
    rb.query('userRole', params.userRole, {"style":"form","explode":true});
    rb.query('excludeRole', params.excludeRole, {"style":"form","explode":true});
    rb.query('recordFrom', params.recordFrom, {});
    rb.query('recordTo', params.recordTo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfGetUserListResult>;
    })
  );
}

userGetUsers.PATH = '/api/Users/Users';
