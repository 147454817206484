/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CalculateFormFieldsVisibilityApiParameters } from '../../models/calculate-form-fields-visibility-api-parameters';
import { ResponseModelOfCalculateFormFieldsVisibilityResult } from '../../models/response-model-of-calculate-form-fields-visibility-result';

export interface FormDataValidationCalculateFormFieldsVisibility$Params {
  
    /**
     * Request Model for creating form instace.
     */
    body: CalculateFormFieldsVisibilityApiParameters
}

export function formDataValidationCalculateFormFieldsVisibility(http: HttpClient, rootUrl: string, params: FormDataValidationCalculateFormFieldsVisibility$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfCalculateFormFieldsVisibilityResult>> {
  const rb = new RequestBuilder(rootUrl, formDataValidationCalculateFormFieldsVisibility.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseModelOfCalculateFormFieldsVisibilityResult>;
    })
  );
}

formDataValidationCalculateFormFieldsVisibility.PATH = '/api/Forms/FormDataValidation/CalculateFormFieldsVisibility';
