/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { formVersionsGetFormVersions } from '../fn/form-versions/form-versions-get-form-versions';
import { FormVersionsGetFormVersions$Params } from '../fn/form-versions/form-versions-get-form-versions';
import { ResponseModelOfGetFormsVersionListResult } from '../models/response-model-of-get-forms-version-list-result';

@Injectable({ providedIn: 'root' })
export class FormVersionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formVersionsGetFormVersions()` */
  static readonly FormVersionsGetFormVersionsPath = '/api/Forms/FormVersions';

  /**
   * Returns form versions according to the input criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formVersionsGetFormVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  formVersionsGetFormVersions$Response(params?: FormVersionsGetFormVersions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseModelOfGetFormsVersionListResult>> {
    return formVersionsGetFormVersions(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns form versions according to the input criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formVersionsGetFormVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formVersionsGetFormVersions(params?: FormVersionsGetFormVersions$Params, context?: HttpContext): Observable<ResponseModelOfGetFormsVersionListResult> {
    return this.formVersionsGetFormVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseModelOfGetFormsVersionListResult>): ResponseModelOfGetFormsVersionListResult => r.body)
    );
  }

}
