import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AppkitThemePickerModule,
  BrandPaletteIconModule,
  DarkModeModule,
  NgxThemeModule,
} from '@pwc/appkit-styled-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { ErrorHttpInterceptor } from './interceptors/error-http.interceptor';
import { HttpActivityIndicationInterceptor } from './interceptors/http-activity-indication.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { LoggedOutComponent } from './modules/logged-out/logged-out.component';
import { NgxCoreModule } from './modules/ngx-core/ngx-core.module';
import { CallbackComponent } from './pages/callback/callback.component';
import { ApiModule } from './services/api/api.module';
import { DeclineLogOutComponent } from './modules/decline-log-out/decline-log-out.component';

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    LoggedOutComponent,
    DeclineLogOutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxCoreModule,
    AppkitThemePickerModule,
    NgxThemeModule,
    DarkModeModule,
    AuthConfigModule,
    ApiModule,
    BrandPaletteIconModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpActivityIndicationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
