import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedUserService } from './shared-user.service';
import { take } from 'rxjs';
import { UserRoles } from '../models/user-get-user.model';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private router: Router,
    private sharedUserService: SharedUserService
  ) { }

  goHome(projectRouting?) {
    this.sharedUserService.currentTenantUser$.pipe(take(1)).subscribe((user) => {
        const isCompanyAdmin = user.tenant.roles.some(v => v.roleId === UserRoles.COMPANY_ADMIN )
        const isPwcAdmin = user.tenant.roles.some(v => v.roleId === UserRoles.PWC_ADMIN )
        if (isCompanyAdmin || isPwcAdmin) {
          switch (projectRouting?.id) {
            case 'Created':
              this.router.navigate(['/homepage/projects/Created'], {
                queryParamsHandling: 'merge',
              });
              break;

            case 'Rejected':
              this.router.navigate(['/homepage/projects/Rejected'], {
                queryParamsHandling: 'merge',
              });
              break;

            default:
              this.router.navigate(['/homepage/projects/Created'], {
                queryParamsHandling: 'merge',
              });
              break;
          }
        } else {
          switch (projectRouting?.typeId) {
            case 'Submitted':
              this.router.navigate(
                ['/homepage/projects/my-requests/Submitted'],
                {
                  queryParamsHandling: 'merge',
                }
              );
              break;
  
            case 'Rejected':
              this.router.navigate(['/homepage/projects/my-requests/Rejected'], {
                queryParamsHandling: 'merge',
              });
              break;
  
            case 'Approved':
              this.router.navigate(['/homepage/projects/my-requests/Approved'], {
                queryParamsHandling: 'merge',
              });
              break;
  
            case 'In-progress':
              this.router.navigate(
                ['/homepage/projects/my-requests/In-progress'],
                {
                  queryParamsHandling: 'merge',
                }
              );
              break;
  
            case 'Deleted':
              this.router.navigate(['/homepage/projects/my-requests/Deleted'], {
                queryParamsHandling: 'merge',
              });
              break;
  
            case 'Assigned':
              this.router.navigate(['/homepage/projects/my-tasks/Assigned'], {
                queryParamsHandling: 'merge',
              });
              break;
  
            case 'Ready-to-review':
              this.router.navigate(
                ['/homepage/projects/my-tasks/Ready-to-review'],
                {
                  queryParamsHandling: 'merge',
                }
              );
              break;
  
            case 'Review':
              this.router.navigate(['/homepage/projects/my-tasks/Review'], {
                queryParamsHandling: 'merge',
              });
              break;
  
            case 'Not-assigned':
              this.router.navigate(
                ['/homepage/projects/my-tasks/Not-assigned'],
                {
                  queryParamsHandling: 'merge',
                }
              );
              break;
  
            case 'In-Progress':
              this.router.navigate(['/homepage/projects/my-tasks/In-Progress'], {
                queryParamsHandling: 'merge',
              });
              break;
  
            default:
              this.router.navigate(
                ['/homepage/projects/my-requests/Submitted'],
                {
                  queryParamsHandling: 'merge',
                }
              );
              break;
          }
        }
    });
  }
}
