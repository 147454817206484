/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RequestDocumentSupport } from '../../models/request-document-support';
import { ResponseResult } from '../../models/response-result';

export interface SupportSaveComment$Params {
      body?: {
'Id'?: string | null;
'TenantId'?: string;
'Description'?: string | null;
'SupportRequestId'?: string;
'UserId'?: string;
'UserModel.Id'?: string;
'UserModel.Name'?: string | null;
'UserModel.Email'?: string | null;
'FirstLastName'?: string | null;
'TimeTracker'?: string | null;
'Files'?: Array<Blob> | null;
'Documents'?: Array<RequestDocumentSupport> | null;
}
}

export function supportSaveComment(http: HttpClient, rootUrl: string, params?: SupportSaveComment$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResult>> {
  const rb = new RequestBuilder(rootUrl, supportSaveComment.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseResult>;
    })
  );
}

supportSaveComment.PATH = '/api/Support/tickets/savecomment';
